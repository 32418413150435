import React from 'react';
import NumberFormat from 'react-number-format';
import { Subscription } from '../../Interfaces';

type SubscriptionPopupProps = {
  subscriptionName: string;
  subscriptionInfo: Subscription | undefined;
  handleHideSubscriptionPopup: () => void;
};

function SubscriptionPopup(props: SubscriptionPopupProps): React.ReactElement {
  const { subscriptionName, subscriptionInfo, handleHideSubscriptionPopup } =
    props;
  return (
    <div>
      <div className="modal-header">
        <h4 className="modal-title" id="exampleModalLabel">
          <b>Subscription info for {subscriptionName}</b>
        </h4>
        <button
          type="button"
          className="close"
          onClick={handleHideSubscriptionPopup}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-4">Payment Amount:</div>
            <div className="col-md-7">
              <NumberFormat
                value={subscriptionInfo ? subscriptionInfo.amount : ''}
                displayType="text"
                thousandSeparator
                prefix="$"
                decimalScale={2}
                fixedDecimalScale
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-4">Status:</div>
            <div className="col-md-7">
              {subscriptionInfo ? subscriptionInfo.status : ''}
            </div>
          </div>
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-4">Next Payment Date:</div>
            <div className="col-md-7">
              {subscriptionInfo && subscriptionInfo.nextScheduledPayment
                ? new Date(
                    subscriptionInfo.nextScheduledPayment,
                  ).toLocaleDateString()
                : 'n/a'}
            </div>
          </div>
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-4">Promo:</div>
            <div className="col-md-7">
              {subscriptionInfo && subscriptionInfo.promoCode
                ? subscriptionInfo.promoCode
                : 'n/a'}
            </div>
          </div>
        </div>
        <div className="col-sm-12 text-right">
          <button
            type="button"
            className="btn"
            onClick={handleHideSubscriptionPopup}
          >
            Close
          </button>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
}

export default SubscriptionPopup;
