import { createContext } from 'react';
import Profile from '../models/Profile';

export interface ProfileContextInterface {
  profile: Profile;
}

const initialGlobalState = {
  profile: new Profile(),
};

export const ProfileContext =
  createContext<ProfileContextInterface>(initialGlobalState);
