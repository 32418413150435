import React, { useRef, useState } from 'react';
import { Divider } from '@mui/material';
import NumberFormat from 'react-number-format';
import { useGlobalProfile } from '../hooks/useGlobalProfile';
import { useGetMonthlyData } from '../hooks/useGetMonthlyData';
import MonthlyDataForm from './DataCenter/MonthlyDataForm';
import { MonthlyData } from '../Interfaces';
import '../css/DataCenter.css';

const months: string[] = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function DataCenter(): React.ReactElement {
  const { profile: globalProfile } = useGlobalProfile();
  const [activeId, setActiveId] = useState(globalProfile.activeEstablishmentId);
  const [editData, setEditData] = useState<MonthlyData>();
  const [formMonth, setFormMonth] = useState(0);
  const [dataFormMode, setDataFormMode] = useState('add');
  const [activeYear, setActiveYear] = useState(new Date().getFullYear());
  const modalFormRef = useRef<HTMLDivElement>(null);
  const modalBGRef = useRef<HTMLDivElement>(null);
  const reportResults = useGetMonthlyData(activeId);

  const handleChangeActiveEstablisment = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const selectedId = parseInt(event.target.value, 10);
    globalProfile.activeEstablishmentId = selectedId;
    reportResults.setFetchId(selectedId);
    setActiveId(selectedId);
  };

  const handleChangeYear = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const selectedYear = parseInt(event.target.value, 10);
    setActiveYear(selectedYear);
  };

  const handleOpenFormModal = (rowData?: MonthlyData): void => {
    if (rowData) {
      setEditData(rowData);
      setDataFormMode('update');
    } else {
      setEditData({
        establishmentId: 0,
        month: 0,
        year: 0,
        squareFootage: globalProfile.getActiveEstablishmentSquareFootage(),
        leaseAmount: globalProfile.getActiveEstablishmentLeaseRate(),
        totalSales: 0,
      });
      setDataFormMode('add');
    }

    if (modalFormRef.current && modalBGRef.current) {
      modalFormRef.current.style.display = 'block';
      modalBGRef.current.style.display = 'block';
    }
  };

  const handleCloseFormModal = (): void => {
    if (modalFormRef.current && modalBGRef.current) {
      modalFormRef.current.style.display = 'none';
      modalBGRef.current.style.display = 'none';
    }
  };

  const handleDataSaved = (): void => {
    reportResults.forceFetch();
    handleCloseFormModal();
  };

  const yearsArray = Array.from(reportResults.reportDataStore.keys());
  [2020, 2021, 2022].forEach(year => {
    if (yearsArray.indexOf(year) === -1) {
      yearsArray.push(year);
    }
  });

  return (
    <div>
      {(() => {
        if (reportResults.isLoadingMonthlyData) {
          return (
            <div id="modal-container">
              <div className="alert alert-info">
                <span
                  className="spinner-border spinner-border-md"
                  role="status"
                  aria-hidden="true"
                />
                <div>Loading Monthly Data...</div>
              </div>
            </div>
          );
        }
        return '';
      })()}
      <div className="modal-backdrop" ref={modalBGRef} />
      <div className="modal" ref={modalFormRef}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Edit Data for {months[formMonth - 1]} {activeYear}
              </h4>
              <button
                type="button"
                className="close"
                onClick={handleCloseFormModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-sm-12">
                <MonthlyDataForm
                  formMode={dataFormMode}
                  editData={editData}
                  targetYear={activeYear}
                  targetMonth={formMonth}
                  formDone={handleCloseFormModal}
                  formSaved={handleDataSaved}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-container-lesspad">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="page-title">Data Center</h3>
            </div>
            <div className="col-md-6 text-right">
              {(() => {
                if (globalProfile.establishments.length === 1) {
                  return <b>{globalProfile.establishments[0].name}</b>;
                }
                return (
                  <select
                    className="form-control"
                    value={activeId}
                    onChange={handleChangeActiveEstablisment}
                    style={{
                      width: '350px',
                      float: 'right',
                      position: 'relative',
                      top: '-5px',
                    }}
                  >
                    {globalProfile.establishments.map(establishment => (
                      <option key={establishment.id} value={establishment.id}>
                        {establishment.name}
                      </option>
                    ))}
                  </select>
                );
              })()}
            </div>
          </div>
          <Divider />
        </div>
      </div>
      <div className="section-container-lesspad">
        <div className="container">
          <select
            className="form-control"
            value={activeYear}
            onChange={handleChangeYear}
            style={{
              width: '150px',
              position: 'relative',
              top: '-5px',
            }}
          >
            {yearsArray
              .sort((a, b) => {
                return b - a;
              })
              .map(year => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
          </select>
          <Divider />
          <br />
          <table className="table table-hover table-bordered">
            <thead className="thead-light">
              <tr>
                <th className="active" scope="col">
                  Month
                </th>
                <th className="active" scope="col">
                  Total Sales
                </th>
                <th className="active" scope="col">
                  Total Square Feet
                </th>
                <th className="active" scope="col">
                  Total Montly Lease Amount
                </th>
                <th className="active" scope="col">
                  {}
                </th>
              </tr>
            </thead>
            <tbody>
              {(() => {
                const rows = [];
                const dataArr =
                  reportResults.reportDataStore.get(activeYear) || [];
                const dateObj = new Date();
                const currentMonth = dateObj.getMonth() + 1;
                const currentYear = dateObj.getFullYear();
                let disableButton = false;

                // Let's update the establishment in the global profile w/ the
                // most recent data we just fecthed
                globalProfile.setActiveEstablishmentData(
                  reportResults.rawMonthlyData,
                );

                for (let i = 0; i < 12; i += 1) {
                  if (
                    activeYear > currentYear ||
                    (activeYear === currentYear && i + 1 > currentMonth)
                  ) {
                    disableButton = true;
                  } else {
                    disableButton = false;
                  }

                  if (dataArr[i]) {
                    rows.push(
                      <tr key={dataArr[i].id}>
                        <td className="text-left">{months[i]}</td>
                        <td align="right">
                          <NumberFormat
                            value={dataArr[i].totalSales}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </td>
                        <td align="right">
                          <NumberFormat
                            value={dataArr[i].squareFootage}
                            displayType="text"
                            thousandSeparator
                          />
                        </td>
                        <td align="right">
                          <NumberFormat
                            value={dataArr[i].leaseAmount}
                            displayType="text"
                            thousandSeparator
                            prefix="$"
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            disabled={disableButton}
                            onClick={() => {
                              setFormMonth(i + 1);
                              handleOpenFormModal(dataArr[i]);
                            }}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>,
                    );
                  } else {
                    rows.push(
                      <tr key={Math.random()}>
                        <td className="text-left">{months[i]}</td>
                        <td />
                        <td />
                        <td />
                        <td>
                          <button
                            type="button"
                            disabled={disableButton}
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                              setFormMonth(i + 1);
                              handleOpenFormModal(dataArr[i]);
                            }}
                          >
                            Edit
                          </button>
                        </td>
                      </tr>,
                    );
                  }
                }
                return rows;
              })()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default DataCenter;
