import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Box, Step, Stepper, StepLabel } from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import Person from '@mui/icons-material/Person';
import AddBusiness from '@mui/icons-material/AddBusiness';
import Settings from '@mui/icons-material/Settings';
import { styled, ThemeProvider } from '@mui/material/styles';
import { StepLabelTheme } from '../themes/MUIThemes';
import OnboardingHeader from './Onboarding/OnboardingHeader';
import UserProfileForm from './UserProfileForm';
import EstablishmentForm from './EstablishmentForm';
import OnboardingSelectPlan from './Onboarding/OnboardingSelectPlan';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(9,163,27) 0%,rgb(7,87,20) 50%,rgb(36,116,49) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 95deg,rgb(9,163,27) 0%,rgb(7,87,20) 50%,rgb(36,116,49) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '2rem',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(7,87,20) 0%, rgb(36,116,49) 50%, rgb(9,163,27) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(7,87,20) 0%, rgb(36,116,49) 50%, rgb(9,163,27) 100%)',
  }),
}));

function ColorlibStepIcon(props: StepIconProps): React.ReactElement {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <Person fontSize="inherit" />,
    2: <AddBusiness fontSize="inherit" />,
    3: <Settings fontSize="inherit" />,
  };

  const { icon } = props;
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(icon)]}
    </ColorlibStepIconRoot>
  );
}

/* const StepLabelTheme = createTheme({
  components: {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.5rem',
        },
      },
    },
  },
}); */

type OnboardingProps = {
  initialStep: number;
  endOnboarding: () => void;
};

function Onboarding(props: OnboardingProps): React.ReactElement {
  const { initialStep, endOnboarding } = props;
  const [currentStep, setCurrentStep] = useState(initialStep);
  const steps = ['User Info', 'Establishment Info'];
  const advanceStep = (): void => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    } else {
      endOnboarding();
    }
  };

  return (
    <div>
      <BrowserRouter>
        <OnboardingHeader />
        <div className="section-container-lesspad">
          <div className="container">
            <h2>Finish Account Setup</h2>
            <Box sx={{ mx: 'auto', width: '80%' }}>
              <Stepper
                alternativeLabel
                activeStep={currentStep}
                connector={<ColorlibConnector />}
              >
                {steps.map(label => (
                  <Step key={label}>
                    <ThemeProvider theme={StepLabelTheme}>
                      <StepLabel
                        classes={{
                          label: "fontSize: '1.5rem",
                        }}
                        StepIconComponent={ColorlibStepIcon}
                      >
                        {label}
                      </StepLabel>
                    </ThemeProvider>
                  </Step>
                ))}
              </Stepper>
              <br />
              <br />
              {(() => {
                switch (currentStep) {
                  case 0:
                    return (
                      <UserProfileForm
                        advanceStep={advanceStep}
                        formMode="onboarding"
                      />
                    );
                  case 1:
                    return (
                      <EstablishmentForm
                        advanceStep={advanceStep}
                        formMode="onboarding"
                        editEstablishmentId={0}
                      />
                    );
                  case 2:
                    return <OnboardingSelectPlan advanceStep={advanceStep} />;
                  default:
                    return (
                      <UserProfileForm
                        advanceStep={advanceStep}
                        formMode="onboarding"
                      />
                    );
                }
              })()}
            </Box>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default Onboarding;
