import React, { useState } from 'react';
import useApiCallV2 from './useApiCallV2';
import {
  EstablishmentData,
  ReportDataResponse,
  ReportData,
} from '../Interfaces';

export const useGetReportData = (): ReportDataResponse => {
  const [hasReportData, setHasReportData] = useState(false);
  const [reportDataMap, setReportDataMap] = React.useState<{
    [subject: string]: ReportData[];
  }>({});
  const [establishmentData, setEstablishmentData] = useState<
    EstablishmentData[]
  >([]);
  const [isLoadingReportData, setIsLoadingReportData] = useState(true);
  const { hasToken, execute } = useApiCallV2();

  const fetchData = async (
    estId: number,
    duration: string,
    count: number,
  ): Promise<boolean> => {
    if (!hasToken) {
      return false;
    }

    let response;

    setIsLoadingReportData(true);

    response = await execute({
      method: 'GET',
      endpoint: `/api/reports/${estId}?period=${duration}&periodCount=${count}`,
    });
    setReportDataMap(response.data);

    response = await execute({
      method: 'GET',
      endpoint: `/api/establishments/${estId}/data?period=${duration}&periodCount=${count}`,
    });
    setEstablishmentData(response.data);

    if (response.error) {
      setHasReportData(false);
    } else {
      setHasReportData(true);
    }

    setIsLoadingReportData(false);

    return true;
  };

  return {
    fetchData,
    hasReportData,
    isLoadingReportData,
    reportDataMap,
    establishmentData,
    hasToken,
  };
};

// export default useGetReportData;
