import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import OnboardingHeader from './Onboarding/OnboardingHeader';
import useApiCall from '../hooks/useApiCall';
import { useGlobalProfile } from '../hooks/useGlobalProfile';

type TermsOfServiceProps = {
  acceptCallback: () => void;
};

function TermsOfService(props: TermsOfServiceProps): React.ReactElement {
  const { acceptCallback } = props;
  const { profile: globalProfile } = useGlobalProfile();
  const [disableInputs, setDisableInputs] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const { hasToken, isLoading, data, error, execute } = useApiCall();

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setDisableInputs(!event.target.checked);
  };

  const handleAcceptTOS = (): void => {
    if (hasToken) {
      setIsSaving(true);
      execute({
        method: 'PUT',
        endpoint: `/api/profiles/${globalProfile?.email}`,
        requestBody: {
          username: globalProfile.email,
          tosAccepted: true,
        },
      });
    }
  };

  useEffect(() => {
    if (isSaving) {
      if (!isLoading) {
        if (
          data.status === 'NOT_ACCEPTABLE' ||
          data.status === 'NOT_FOUND' ||
          data.status === 'CONFLICT'
        ) {
          acceptCallback();
          setIsSaving(false);
        } else if (!error && data.id) {
          setIsSaving(false);
          acceptCallback();
        }
      }
    }
  }, [isLoading]);

  return (
    <div>
      <BrowserRouter>
        <OnboardingHeader />
        <div className="section-container-lesspad">
          <div className="container">
            <div className="row">
              <div className="col-md-12 bottom-space-lg">
                Please read and accept the following Terms and Conditions before continuing:
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 bottom-space-md">
                <div className="text-center"><h3>Terms and Conditions</h3></div>
                <b>1. Introduction</b>
                <br />
                <br />
                You agree to and are bound by the terms and conditions set forth below and in any modified or
                additional terms that Radar Reports, LLC (“Radar Reports”) may publish from time to time
                (collectively, the “Terms of Use”). If you do not agree to all of the terms and conditions
                contained in the Terms of Use, do not access or use the Radar Reports website and related
                services (collectively, the “Website”).
                <br />
                <br />
                Excluding the specific terms and conditions set forth in Section 5 (License Restriction), Radar
                Reports may change these Terms of Use from time to time. Your continued access or use of the
                Website constitutes your acceptance of such changes. Your access and use of the Website will be
                subject to the current version of the Terms of Use posted on the Website at the time of such use.
                If you breach any of the Terms of Use, your license to access or use the Website may be
                terminated by Radar Reports at its discretion.
                <br />
                <br />
                <b>2. Intellectual Property</b>
                <br />
                <br />
                Subject to your compliance with these Terms of Use and the law, you may access and use the
                Website. Radar Reports remains the sole owner of all right, title, and interest in the Website.
                Radar Reports may modify, replace, or discontinue the Website or any part thereof at any time,
                for any reason, with or without notice, in Radar Reports’ sole discretion.
                <br />
                <br />
                All content on the Website (collectively the “Radar Reports Content”) is protected by copyright,
                trademark, patent, trade secret and other intellectual property laws. Any unauthorized use of any
                Radar Reports Content violates such laws and this Terms of Use. Radar Reports does not grant
                any express or implied permission to use any Radar Reports Content. You agree not to copy,
                republish, frame, link to, download, transmit, modify, adapt, create derivative works based on,
                rent, lease, loan, sell, assign, distribute, display, perform, license, sublicense or reverse engineer
                the Website or any Radar Reports Content. In addition, you agree not to use any data mining,
                robots or similar data and/or image gathering and extraction methods in connection with the
                Website or Radar Reports Content. You may not download, distribute, display and/or copy any
                Radar Reports Content. You may not remove any watermarks or copyright notices contained in
                the Radar Reports Content.
                <br />
                <br />
                <b>3. Radar Reports Trademarks</b>
                <br />
                <br />
                For the purposes of these Terms of Use, the term “Trademark(s)” means all common law or
                registered trademarks, logos, service marks, trade names, Internet domain names, or other
                indications of origin now or in the future used by Radar Reports.
                <br />
                <br />
                Nothing contained herein grants or shall be construed to grant you any rights to use any Radar
                Reports Trademark. You agree that you will not use Radar Reports’ Trademarks in any manner
                that might tarnish, disparage, or reflect adversely on such Trademarks or Radar Reports. You
                agree that you will not contest or otherwise challenge (e.g., in any legal action or otherwise), or
                assist or encourage any other person or entity to contest or challenge, the validity of any of Radar
                Reports Trademarks or the Trademark rights claimed by Radar Reports. You agree that you will
                not use any Radar Reports Trademark or any variant thereof (including misspellings) as a domain
                name or as part of a domain name regardless of the top-level domain, or as a metatag, keyword,
                or any other type of programming code or data. You may not at any time, adopt or use, without
                Radar Reports’ prior written consent any word or mark which is similar to or likely to be
                confused with Radar Reports’ Trademarks.
                <br />
                <br />
                The look and feel of the Website, including all page headers, custom graphics, button icons, and
                scripts, is the trade dress and/or trademark or service mark of Radar Reports and may not be
                copied, imitated or used, in whole or in part, without the prior written consent of Radar Reports.
                Reference to any products, services, processes or other information, by trade name, trademark,
                manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or
                recommendation thereof by Radar Reports, unless expressly so stated.
                <br />
                <br />
                You may not use a Radar Reports trademark, logo, image or other proprietary graphic of Radar
                Reports to link to the Website without the prior written consent of Radar Reports. You may not
                frame or hotlink to the Website without the prior written consent of Radar Reports.
                <br />
                <br />
                <b>4. Your Data</b>
                <br />
                <br />
                For any data or other content that you upload to the Website (collectively “Your Data”), you
                represent and warrant that: (i) you have all necessary rights to submit Your Data to the Website
                and grant the licenses set forth herein; (ii) Radar Reports will not need to obtain licenses from
                any third party or pay royalties to any third party with respect to Your Data; (iii) Your Data does
                not infringe any third party&apos;s rights, including intellectual property rights and privacy rights; and
                (iv) Your Data complies with these Terms of Use and all applicable laws. You may not upload
                data or other content that infringes any third party&apos;s right of publicity or privacy, or that by
                uploading violates any contract, agreement, law, statute, or regulation.
                <br />
                <br />
                Subject to Section 5 (License Restriction), by uploading Your Data to the Website you grant
                Radar Reports an irrevocable, perpetual, non-exclusive, transferable, sublicensable, worldwide,
                fully paid, royalty-free license to reproduce, make available, display, translate, modify, create
                derivative works from, distribute, and otherwise use any of Your Data through any medium,
                whether alone or in combination with other data or materials, in any manner and by any means,
                method or technology, whether now known or hereafter created.
                <br />
                <br />
                <b>5. License Restriction</b>
                <br />
                <br />
                The license to Your Data granted to Radar Reports in Section 4 (Your Data) does not grant Radar
                Reports the right to publicly display (or otherwise provide to a third party) any of Your Data in a
                manner that would allow someone to reasonably identify, or would otherwise reasonably
                indicate, who originally provided or generated such data or content.
                <br />
                <br />
                <b>6. Links to Third Party Sites</b>
                <br />
                <br />
                In the event that the Website is available through any third-party platform, or if Radar Reports
                provides links from the Website to any third-party platform or permits any third party to link
                from its platform to the Website, you understand and agree that Radar Reports makes no
                warranty of any kind, express or implied, and accepts no responsibility for any content or
                practices of such third parties or their platforms. Such platforms are not under the control of
                Radar Reports, and Radar Reports provides and/or permits these links only as a convenience to
                you. The inclusion of any link does not imply affiliation, endorsement, or adoption by Radar
                Reports.
                <br />
                <br />
                <b>7. Warranties and Disclaimers</b>
                <br />
                <br />
                Your use of the Website is at your own risk. The Website is provided by Radar Reports under
                these Terms of Use “as is” without warranty of any kind, either express, implied, statutory or
                otherwise. Radar Reports expressly disclaims any and all warranties of any kind, whether
                express or implied, to each and any service available from the Website, including, but not limited
                to the implied warranties of merchantability, fitness for a particular purpose, non-infringement,
                and any other warranty that might arise under any law. Without limiting the foregoing, Radar
                Reports makes no warranty that: (i) the Website will meet your requirements; (ii) access to the
                Website will be uninterrupted; (iii) the quality of the Website will meet your expectations; and
                (iv) any errors or defects in the Website, services or materials will be corrected. Radar Reports
                makes no representations or warranties that any of Your Data uploaded by you will be available
                through the Website or will be stored by the Website, that the Website will meet your needs, or
                that Radar Reports will continue to support any particular feature of the Website.
                <br />
                <br />
                <b>8. Limited Liability</b>
                <br />
                <br />
                By accessing or using the Website you agree that Radar Reports’ liability is limited to the fullest
                extent allowed by law. Radar Reports will not be liable for any unintentional damage, any actual,
                incidental, or consequential damage, or for any loss or claim of any kind.
                <br /><br />
                If you are dissatisfied with any portion of the Website or with any statement in these Terms of
                Use, your sole remedy is to stop using the Website and cancel your subscription. Radar Reports’
                total liability in any matter related to the Website or these Terms of Use is limited to the
                aggregate amount you paid to Radar Reports during the 12-month period preceding the event
                giving rise to the liability.
                <br />
                <br />
                <b>9. Your Indemnity</b>
                <br />
                <br />
                You agree that you will indemnify and hold Radar Reports, and their respective officers,
                directors, members, employees, agents, successors, and assigns (the “Radar Reports Parties”)
                harmless from any claims, damages, or other expenses (including attorney’s fees) that result from
                your use of the Website and (i) your violation of these Terms of Use; (ii) your violation of
                another person’s rights; or (iii) any claim related to Your Data. This indemnification obligation
                will continue after you stop using the Website.
                <br />
                <br />
                <b>10. Miscellaneous</b>
                <br />
                <br />
                Any controversy or claim arising out of or relating to these Terms of Use, or the breach thereof,
                shall be settled by binding arbitration administered under the Commercial Arbitration Rules of
                the American Arbitration Association in effect on the date of the commencement of arbitration,
                rather than in court, and judgment on the award rendered by the arbitrator(s) may be entered in
                any court having jurisdiction thereof or having jurisdiction over the relevant party or its assets.
                The place of arbitration shall be in San Diego, California. There shall be one arbitrator. Each
                party shall bear its own costs in the arbitration. Radar Reports shall also have the right to
                commence and prosecute any legal or equitable action or proceeding before any court of
                competent jurisdiction to obtain injunctive or other relief against you in the event that, in the
                opinion of Radar Reports, such action is necessary or desirable.
                <br />
                <br />
                These Terms of Use are governed by and shall be construed in accordance with the laws of the
                State of California, without respect to its conflict of laws principles.
                <br />
                <br />
                These Terms of Use shall be construed neither against nor in favor of any party, but rather in
                accordance with the fair meaning of the language hereof. The invalidity or unenforceability of
                any part of these Terms of Use shall not affect the validity or enforceability of the balance hereof.
                Radar Reports may assign any or all of these Terms of Use, and may assign or delegate, in whole
                or in part, any of its rights or obligations under these Terms of Use. You may not assign these
                Terms of Use, in whole or in part, nor transfer or sublicense your rights under these Terms of Use
                to any third party.
                <br />
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 bottom-space-md">
                <input
                  className="form-check-input"
                  type="checkbox"
                  onChange={handleCheckBoxChange}
                />
                <label className="form-check-label">
                  &nbsp;&nbsp;By checking by box I agree to the terms and conditions described above.
                </label>
                <br />
                <button
                  onClick={handleAcceptTOS}
                  type="button"
                  disabled={disableInputs}
                  className="btn btn-primary"
                >
                  <div
                    style={{
                      float: 'right',
                      paddingTop: '2px',
                      paddingLeft: '4px',
                      paddingRight: '4px',
                    }}
                  >
                    Accept Terms of Service
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </BrowserRouter>
    </div>
  );
}
export default TermsOfService;
