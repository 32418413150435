import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../../assets/logo-new-mdfull.png';

function OnboardingHeader(): React.ReactElement {
  const { logout } = useAuth0();

  return (
    <header>
      <nav className="navbar  navbar-fixed-top navbar-default">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle uarr collapsed"
              data-toggle="collapse"
              data-target="#navbar-collapse-uarr"
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <Link className="navbar-brand" to="/">
              <img
                src={logo}
                className="navbar-logo-img"
                style={{
                  height: '60px',
                  marginTop: '-18px',
                  marginLeft: '-15px',
                }}
                alt=""
              />
            </Link>
          </div>

          <div className="collapse navbar-collapse" id="navbar-collapse-uarr">
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link
                  to="/"
                  onClick={() => logout({ returnTo: window.location.origin })}
                >
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default OnboardingHeader;
