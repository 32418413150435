import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider } from '@mui/material';
import FormErrorsBox from './FormErrorsBox';
import useApiCall from '../hooks/useApiCall';
import { SuggestEstablishmentFormErrorClasses } from '../Interfaces';

function SuggestEstablishment(): React.ReactElement {
  const nav = useNavigate();
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [hideErrorBox, setHideErrorBox] = useState('hide');
  const { hasToken, isLoading, data, error, execute } = useApiCall();
  const inputBoxRef = useRef<HTMLInputElement>(null);
  const [formData, setFormData] = useState({
    establishmentName: '',
    address2: '',
    phoneNumber: '',
    contactName: '',
    contactEmail: '',
  });
  const [errorClass, setErrorClass] =
    useState<SuggestEstablishmentFormErrorClasses>({
      establishmentName: '',
      address1: '',
      address2: '',
      phoneNumber: '',
      contactName: '',
      contactEmail: '',
    });

  const [autoCompleter, setAutoCompleter] =
    React.useState<google.maps.places.Autocomplete>();

  // const handleCancel = (event: React.MouseEvent<HTMLElement>): void => {
  const handleCancel = (): void => {
    nav('/establishments');
  };

  //
  // function: handleFormSubmit
  // desc: react to user submitting the form. validate input and then make API call
  //       to store it
  //
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const place = autoCompleter?.getPlace();

    // BEGIN form data validation
    let errorsFound = false;
    const localErrorArr: string[] = [];

    const localErrorClasses: SuggestEstablishmentFormErrorClasses = {
      establishmentName: '',
      address1: '',
      address2: '',
      phoneNumber: '',
      contactName: '',
      contactEmail: '',
    };

    const errorMessages = {
      establishmentName: 'Name must be a string less than 64 characters long',
      address1: 'Please enter and select a valid address from picker.',
      address2: 'Suite/Unit must be a string less than 64 characters long',
      phoneNumber:
        'Phone number is optional, but must be less that 16 characters if entered.',
      contactName: 'Contact name must be less than 64 characters long',
      contactEmail:
        'Email is optional, but must be less that 128 characters if entered.',
    };

    const formFieldRegexes = {
      establishmentName: /^.{1,64}$/,
      address2: /^.{0,64}$/,
      phoneNumber: /^.{0,16}$/,
      contactName: /^.{0,64}$/,
      contactEmail: /^.{0,128}$/,
    };

    if (!place || !place.geometry) {
      localErrorClasses.address1 = 'has-error';
      errorsFound = true;
      localErrorArr.push(errorMessages.address1);
    }

    Object.keys(formFieldRegexes).forEach(formItem => {
      const formValue = (formData[formItem as keyof unknown] as string) || '';
      if (
        !formValue.toString().match(formFieldRegexes[formItem as keyof unknown])
      ) {
        errorsFound = true;
        localErrorArr.push(errorMessages[formItem as keyof unknown]);
        localErrorClasses[
          formItem as keyof SuggestEstablishmentFormErrorClasses
        ] = 'has-error';
      }
    });

    if (errorsFound) {
      setHideErrorBox('');
    } else {
      setHideErrorBox('hide');
    }
    setErrorClass({ ...errorClass, ...localErrorClasses });
    setFormErrors(localErrorArr);
    // END form data validation

    if (!hasToken || !place?.address_components) {
      // we don't meet the conditions to send API request
      return;
    }

    // if we got here we are going to send API request...let's get it ready
    setIsSaving(true);
    let address1 = '';
    let city = '';
    let state = '';
    let zipCode = '';

    if (place?.address_components) {
      // get address info from Autocompleter
      address1 = `${place?.address_components[0].short_name} ${place?.address_components[1].short_name}`;
      city = place?.address_components[3].long_name;
      state = place?.address_components[5].short_name;
      zipCode = place?.address_components[7].short_name;
    }

    const requestBody = {
      businessName: formData.establishmentName,
      businessAddress: `${address1} ${formData.address2} ${city}, ${state} ${zipCode}`,
      businessPhone: formData.phoneNumber,
      contactName: formData.contactName,
      contactEmail: formData.contactEmail,
    };

    execute({
      method: 'POST',
      endpoint: '/api/public/recommendations',
      requestBody,
    });
  };

  const createGMapsAutoCompleter = (): void => {
    // center is San Diego
    const center = { lat: 32.7515739, lng: -117.08 };
    // Create a bounding box with sides ~30km away from the center point
    const defaultBounds = {
      north: center.lat + 0.3,
      south: center.lat - 0.3,
      east: center.lng + 0.3,
      west: center.lng - 0.3,
    };

    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'geometry'],
      strictBounds: false,
      types: ['address'],
    };

    const autoComplete = new google.maps.places.Autocomplete(
      inputBoxRef.current as HTMLInputElement,
      options,
    );
    setAutoCompleter(autoComplete);
  };

  useEffect(() => {
    if (!autoCompleter) {
      createGMapsAutoCompleter();
    }

    if (isSaving) {
      if (!isLoading) {
        if (
          data.status === 'NOT_ACCEPTABLE' ||
          data.status === 'NOT_FOUND' ||
          data.status === 'CONFLICT'
        ) {
          // We got an error in the API call
          setHideErrorBox('');
          setFormErrors([data.message || '']);
          setIsSaving(false);
        } else if (!error && data) {
          // saved
          setShowThankYou(true);
        }
      }
    }
  }, [isLoading]);

  //
  // function: handleFormChange
  // desc: react to user changing values in the input fields of form.
  //        save values to state object for use when form is submitted
  //
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  if (showThankYou) {
    return (
      <div className="section-container-lesspad">
        <div className="container">
          <h4 className="text-center">
            Thank you for your suggestion. When we are able to get your
            suggested establishment onto Radar Report, we will contact you.
          </h4>
        </div>
      </div>
    );
  }

  return (
    <div className="section-container-lesspad">
      <div className="container">
        <h3 className="page-title">Suggest an Establishment</h3>
        <Divider />
        <br />
        <h4 className="text-center">
          Use the form below to suggest an establisment you would like in your
          peer group. We will work to get them onto Radar Report.
        </h4>
        <br />
      </div>
      <div className="container">
        <FormErrorsBox
          formErrors={formErrors}
          hideErrorBoxClass={hideErrorBox}
        />
      </div>
      <div className="container">
        <form onSubmit={handleFormSubmit}>
          <div className={`form-group row ${errorClass.establishmentName}`}>
            <label
              htmlFor="establishmentName"
              className="col-sm-2 col-form-label"
            >
              Name
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="establishmentName"
                name="establishmentName"
                placeholder="Establishment Name"
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className={`form-group row ${errorClass.address1}`}>
            <label htmlFor="address1" className="col-sm-2 col-form-label">
              Address
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="address1"
                name="address1"
                placeholder="Address"
                onChange={handleFormChange}
                ref={inputBoxRef}
              />
            </div>
          </div>
          <div className={`form-group row ${errorClass.address2}`}>
            <label htmlFor="address2" className="col-sm-2 col-form-label" />
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="address2"
                name="address2"
                placeholder="Suite / Unit / Etc"
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className={`form-group row ${errorClass.phoneNumber}`}>
            <label htmlFor="phoneNumber" className="col-sm-2 col-form-label">
              Phone Number (optional)
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="555-111-9876"
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className={`form-group row ${errorClass.contactName}`}>
            <label htmlFor="contactName" className="col-sm-2 col-form-label">
              Contact Name (optional)
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="contactName"
                name="contactName"
                placeholder=""
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className={`form-group row ${errorClass.contactEmail}`}>
            <label htmlFor="contactEmail" className="col-sm-2 col-form-label">
              Contact Email (optional)
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="contactEmail"
                name="contactEmail"
                placeholder=""
                onChange={handleFormChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-12 text-right">
              <span>
                <button
                  onClick={handleCancel}
                  type="button"
                  disabled={isSaving}
                  className="btn"
                >
                  <div
                    style={{
                      float: 'right',
                      paddingTop: '2px',
                      paddingLeft: '4px',
                      paddingRight: '4px',
                    }}
                  >
                    Cancel
                  </div>
                </button>
                &nbsp;
              </span>
              <button
                type="submit"
                disabled={isSaving}
                className="btn btn-primary"
              >
                {(() => {
                  if (isSaving) {
                    return (
                      <span>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <div
                          style={{
                            float: 'right',
                            paddingTop: '2px',
                            paddingLeft: '4px',
                          }}
                        >
                          &nbsp;Saving...
                        </div>
                      </span>
                    );
                  }

                  return (
                    <div
                      style={{
                        float: 'right',
                        paddingTop: '2px',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                      }}
                    >
                      &nbsp;Save
                    </div>
                  );
                })()}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

// issues
// 500 error when tryig to update existing establishment
// need to check return value and update establishment info in global profile

export default SuggestEstablishment;
