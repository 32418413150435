import React, { useRef, useState } from 'react';
import { Divider } from '@mui/material';
import UserProfileForm from './UserProfileForm';
import EstablishmentForm from './EstablishmentForm';
import ToastDataImportSettings from './Profile/ToastDataImportSettings';
import EstablishmentTable from './Profile/EstablishmentTable';
import SubscriptionPopup from './Profile/SubscriptionPopup';
import DataImportConfigTable from './Profile/DataImportConfigTable';
import { useGlobalProfile } from '../hooks/useGlobalProfile';
import { Subscription } from '../Interfaces';
import '../css/Profile.css';

function Profile(): React.ReactElement {
  const [showEstablishmentForm, setShowEstablishmentForm] = useState(false);
  const [showDataImportForm, setShowDataImportForm] = useState(false);
  const [establishmentFormTitle, setEstablishmentFormTitle] = useState('');
  const [subscriptionInfo, setSubscriptionInfo] = useState<Subscription>();
  const [subscriptionName, setSubscriptionName] = useState('');
  const [editId, setEditId] = useState(0);
  const { profile: globalProfile } = useGlobalProfile();
  const modalSubscriptionRef = useRef<HTMLDivElement>(null);
  const modalBGRef = useRef<HTMLDivElement>(null);
  const advanceStep = (): void => {
    // console.log('Done Saving');
  };

  const closeEstablishmentForm = (): void => {
    setShowEstablishmentForm(false);
  };

  const handleAddEstablishment = (
    event: React.FormEvent<HTMLFormElement>,
  ): void => {
    event.preventDefault();
    setEditId(0);
    setEstablishmentFormTitle('Add Establishment');
    setShowEstablishmentForm(true);
  };

  const handleEditEstablishment = (id: number): void => {
    setEditId(id);
    setEstablishmentFormTitle('Edit Establishment');
    setShowEstablishmentForm(true);
  };

  const handleShowEditDataFeedPopup = (establishmentId: number): void => {
    setEditId(establishmentId);
    setShowDataImportForm(true);
  };

  const handleHideEditDataFeedPopup = (): void => {
    setShowDataImportForm(false);
  };

  const handleShowSubscriptionPopup = (establishmentId: number): void => {
    const billingEst = globalProfile.establishments.find(
      est => est.id === establishmentId,
    );
    if (billingEst) {
      setSubscriptionName(billingEst.name);

      if (billingEst.subscriptions) {
        const subscription = billingEst.subscriptions.find(
          sub => sub.status !== 'CANCELLED',
        );
        setSubscriptionInfo(subscription);
      }
    }

    if (modalSubscriptionRef.current && modalBGRef.current) {
      modalSubscriptionRef.current.style.display = 'block';
      modalBGRef.current.style.display = 'block';
    }
  };

  const handleHideSubscriptionPopup = (): void => {
    if (modalSubscriptionRef.current && modalBGRef.current) {
      modalSubscriptionRef.current.style.display = 'none';
      modalBGRef.current.style.display = 'none';
    }
  };

  return (
    <div>
      {(() => {
        if (showEstablishmentForm) {
          return (
            <div className="section-container-lesspad">
              <div className="container" style={{ display: 'block' }}>
                <h3 className="page-title">{establishmentFormTitle}</h3>
                <Divider />
                <br />
                <EstablishmentForm
                  advanceStep={closeEstablishmentForm}
                  formMode="profilePage"
                  editEstablishmentId={editId}
                />
              </div>
            </div>
          );
        }
        if (showDataImportForm) {
          return (
            <div className="section-container-lesspad">
              <div className="container" style={{ display: 'block' }}>
                <h3 className="page-title">
                  Toast&reg; Data Import:&nbsp;
                  {globalProfile.getEstablishmentWithId(editId)?.name}
                </h3>
                <Divider />
                <br />
                <ToastDataImportSettings
                  handleHideEditDataFeedPopup={handleHideEditDataFeedPopup}
                  editId={editId}
                />
              </div>
            </div>
          );
        }
        // We're not showing any forms so just render the full page
        return (
          <div>
            {/* BEGIN: Render the hidden popup for showing the subscription info */}
            <div className="modal-backdrop" ref={modalBGRef} />
            <div className="modal" ref={modalSubscriptionRef}>
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <SubscriptionPopup
                    subscriptionName={subscriptionName}
                    subscriptionInfo={subscriptionInfo}
                    handleHideSubscriptionPopup={handleHideSubscriptionPopup}
                  />
                </div>
              </div>
            </div>
            {/* END: Render the hidden popup for showing the subscription info */}
            <div className="section-container-lesspad">
              <div className="container">
                <h3 className="page-title">Your Info</h3>
                <Divider />
                <br />
                <UserProfileForm
                  advanceStep={advanceStep}
                  formMode="profilePage"
                />
              </div>
            </div>
            <div className="section-container-lesspad">
              <DataImportConfigTable />
            </div>
            <div className="section-container-lesspad">
              <EstablishmentTable
                handleAddEstablishment={handleAddEstablishment}
                handleEditEstablishment={handleEditEstablishment}
                handleShowSubscriptionPopup={handleShowSubscriptionPopup}
                handleShowEditDataFeedPopup={handleShowEditDataFeedPopup}
              />
            </div>
          </div>
        );
      })()}
    </div>
  );
}

export default Profile;
