import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import mapImage from '../assets/establishments_screen.png';
import graphImage from '../assets/dashboard_screen.png';
import '../css/Welcome.css';

function Welcome(): React.ReactElement {
  const nav = useNavigate();

  return (
    <div className="section-container-lesspad">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h3 className="page-title">Welcome to Radar Report!</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 bottom-space-lg">
            Thank you for joining the community. Soon you will gain access to
            valuable information regarding the success of your business in
            comparison to the marketplace. Please follow the steps below to get
            the most of our tool. Rest assured your information is completely
            secure and anonymous.
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h3 className="page-title">Set Up Point Of Sale Data Feed</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 bottom-space-md">
            Configure Radar Report to automatically collect data from Toast.{' '}
            <a
              href="https://public-radarreport-documents.s3.us-east-2.amazonaws.com/RadarReport_SetUpToastInstructions.pdf"
              target="blank"
            >
              Download Instructions
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h3 className="page-title">Choose Your Comparable Group</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 bottom-space-md">
            Create a comparable group for your establishment on the{' '}
            <Link to="/establishments">Establishments</Link> page. Choose at
            least four of the most comparable businesses using Radar Report.
            This group will generate a customized net sales average, allowing
            you to gauge how you compare to your closest competitors.
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center bottom-space-lg">
            <img
              className="welcome_screenshot"
              src={mapImage}
              alt="Peer Selection"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center bottom-space-lg">
            <button
              type="button"
              className="btn btn-lg btn-primary"
              onClick={() => {
                nav('/establishments');
              }}
            >
              Go to Establishments
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <h3 className="page-title">Dashboard</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 bottom-space-md">
            After you have created a Comparable Group and set up your data feed,
            view the <Link to="/">Dashboard</Link> for a view of how you stack
            up against your peers.
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center bottom-space-lg">
          <img
            className="welcome_screenshot"
            src={graphImage}
            alt="Peer Selection"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-center bottom-space-lg">
          <button
            type="button"
            className="btn btn-lg btn-primary"
            onClick={() => {
              nav('/');
            }}
          >
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}
export default Welcome;
