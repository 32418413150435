import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import { ToolTipTheme } from '../../themes/MUIThemes';

type EstablishmentListItemActionProps = {
  establishmentId: number;
  showAddButton: boolean;
  addEstablishment: (establishmentId: number) => void;
};

function EstablishmentListItemAction(
  props: EstablishmentListItemActionProps,
): React.ReactElement | null {
  const { establishmentId, showAddButton, addEstablishment } = props;

  if (showAddButton) {
    return (
      <ThemeProvider theme={ToolTipTheme}>
        <Tooltip title="Add as Peer" placement="top">
          <IconButton
            edge="end"
            aria-label="Add"
            onClick={() => {
              addEstablishment(establishmentId);
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </ThemeProvider>
    );
  }

  return null;
}

export default EstablishmentListItemAction;
