import React, { useEffect, useState } from 'react';
import './App.css';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import FullUserApp from './components/FullUserApp';
import TermsOfService from './components/TermsOfService';
import Onboarding from './components/Onboarding';
import Loading from './components/Loading';
import useGetProfile from './hooks/useGetProfile';
import { useGlobalProfile } from './hooks/useGlobalProfile';
import { Establishment } from './Interfaces';

function App(): React.ReactElement {
  const { profile: globalProfile } = useGlobalProfile();
  const { user } = useAuth0();
  const { isLoadingProfile, hasProfile, profile } = useGetProfile(user?.email);
  const [showFullApp, setShowFullApp] = useState(false);
  const [showOnboardingOne, setShowOnboardingOne] = useState(false);
  const [showFullOnboarding, setShowFullOnboarding] = useState(false);
  const [showAcceptTOS, setShowAcceptTOS] = useState(!globalProfile.tosAccepted);
  const endOnboarding = (): void => {
    setShowFullApp(true);
  };

  useEffect(() => {
    if (!isLoadingProfile) {
      if (hasProfile) {
        globalProfile.id = profile?.id as number;
        globalProfile.firstName = profile?.firstName as string;
        globalProfile.lastName = profile?.lastName as string;
        globalProfile.email = profile?.email as string;
        globalProfile.id = profile?.id as number;
        globalProfile.phoneNumber = profile?.phone as number;
        globalProfile.tosAccepted = profile?.tosAccepted as boolean;
        globalProfile.establishments =
          profile?.establishments as Establishment[];

        if (
          profile?.establishments?.length &&
          profile?.establishments.length > 0
        ) {
          globalProfile.sortEstablishments();
          globalProfile.activeEstablishmentId = profile.establishments[0].id;
          setShowFullApp(true);
        } else {
          setShowOnboardingOne(true);
        }
      } else {
        setShowFullOnboarding(true);
      }
    } else {
      globalProfile.email = user?.email as string;
    }
  }, [isLoadingProfile]);

  useEffect(() => {
    setShowAcceptTOS(!globalProfile.tosAccepted);
  }, [profile]);

  const handleTOSAccepted = ():void => {
    setShowAcceptTOS(false);
  };

  return (
    <div>
      {(() => {
        if (showFullApp) {
          if (showAcceptTOS) {
            return <TermsOfService acceptCallback={handleTOSAccepted} />;
          }
          return <FullUserApp />;
        }

        if (showOnboardingOne) {
          // if they don't have an establishment they need to
          // complete the onboarding starting at step 2
          return <Onboarding initialStep={1} endOnboarding={endOnboarding} />;
        }

        if (showFullOnboarding) {
          // they've got nothing. start onboarding at the beginning
          // return <Onboarding initialStep={0} />;
          return <Onboarding initialStep={0} endOnboarding={endOnboarding} />;
        }

        return (
          <Loading message="Authenticating Session and Loading Profile Information" />
        );
      })()}
    </div>
  );
}

export default withAuthenticationRequired(App, {
  // Show a message while the user waits to be redirected to the login page.
  onRedirecting: () => (
    <Loading message="Redirecting to Secure Authentication Portal" />
  ),
});
