import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Divider } from '@mui/material';
import { useGlobalProfile } from '../hooks/useGlobalProfile';
import RadarLineChart from './RadarLineChart';
import { useGetReportData } from '../hooks/useGetReportData';
import { ChartCategoryEnum } from '../models/ChartCategoryEnum';
import '../css/Dashboard.css';

function Dashboard(): React.ReactElement {
  const { profile: globalProfile } = useGlobalProfile();
  const [activeId, setActiveId] = useState(globalProfile.activeEstablishmentId);
  const [periodDuration, setPeriodDuration] = useState('MONTHLY');
  const [dataPointCount, setDataPointCount] = useState(12)
  const getReportDataHook = useGetReportData();
  const location = useLocation();

  // update url to include selected establishemntId and perdiod
  const updateURL = (establishmentId: number, period: string): void => {
    const searchParams = new URLSearchParams();
    searchParams.set('establishmentId', establishmentId.toString());
    searchParams.set('period', period);


    const newURL = `${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState(null, '', newURL);
  };

  const handleChangeActiveEstablisment = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const pickedEstablishmentId = parseInt(event.target.value, 10);
    globalProfile.activeEstablishmentId = pickedEstablishmentId;
    setActiveId(pickedEstablishmentId);
    // Update URL with id changes
    updateURL(pickedEstablishmentId, periodDuration);
  };

  const handleDurationChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const newDuration = event.target.value;
    setPeriodDuration(newDuration);
    // update url when duration changes
    updateURL(activeId, newDuration);
  };

  const handleDataPointCountChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const newDataPointCount = +event.target.value;
    setDataPointCount(newDataPointCount);
  };

  useEffect(() => {
    getReportDataHook.fetchData(activeId, periodDuration, dataPointCount);
  }, [activeId, getReportDataHook.hasToken, periodDuration, dataPointCount]);

  // hook to use url parameters for estId & duration if they exist
  useEffect(() => {
    // Parse URL parameters
    const searchParams = new URLSearchParams(location.search);
    const establishmentIdParam = searchParams.get('establishmentId');
    const periodParam = searchParams.get('period');

    // Update state based on URL parameters if they exist
    if (establishmentIdParam) {
      globalProfile.activeEstablishmentId = parseInt(establishmentIdParam, 10);
      setActiveId(globalProfile.activeEstablishmentId);
    }
    if (periodParam) {
      setPeriodDuration(periodParam);
    }
  }, [location.search]); //

  return (
    <div>
      {(() => {
        if (getReportDataHook.isLoadingReportData) {
          return (
            <div id="modal-container">
              <div className="alert alert-info">
                <span
                  className="spinner-border spinner-border-md"
                  role="status"
                  aria-hidden="true"
                />
                <div>Loading Report Data...</div>
              </div>
            </div>
          );
        }
        return '';
      })()}
      <div className="section-container-lesspad">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h3 className="page-title">Dashboard</h3>
            </div>
            <div className="col-md-6 text-right">
              {(() => {
                if (globalProfile.establishments.length === 1) {
                  return globalProfile.establishments[0].name;
                }
                return (
                  <select
                    className="form-control"
                    value={activeId}
                    onChange={handleChangeActiveEstablisment}
                    style={{
                      width: '350px',
                      float: 'right',
                      position: 'relative',
                      top: '-5px',
                    }}
                  >
                    {globalProfile.establishments.map(establishment => (
                      <option key={establishment.id} value={establishment.id}>
                        {establishment.name}
                      </option>
                    ))}
                  </select>
                );
              })()}
            </div>
          </div>
          <Divider />
        </div>
      </div>
      {(() => {
        const selectedEstablishment = globalProfile.getActiveEstablishment();
        if (selectedEstablishment?.pointOfSaleIntegration === 'MANUAL') {
          return (
            <div className="section-container-lesspad">
              <div className="container text-center">
                <h4>
                  You must enable POS Integration to access reports. Click&nbsp;
                  <a
                    href="https://public-radarreport-documents.s3.us-east-2.amazonaws.com/RadarReport_SetUpToastInstructions.pdf"
                    target="blank"
                  >
                    here
                  </a>
                  &nbsp;for instructions.
                </h4>
              </div>
            </div>
          );
        }
        if (
          globalProfile.hasFullProfile() &&
          selectedEstablishment &&
          selectedEstablishment.monthlyData.length > 0 &&
          getReportDataHook.hasReportData
        ) {
          const reportMap = getReportDataHook.reportDataMap;

          if (getReportDataHook.establishmentData[0]) {
            return (
              <div className="container">
                <div className="row">
                  <div
                    className="col-md-3"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Report Period Duration: &nbsp;
                    <select
                      className="input-sm"
                      value={periodDuration}
                      onChange={handleDurationChange}
                    >
                      <option value="DAILY">Daily</option>
                      <option value="WEEKLY">Weekly</option>
                      <option value="MONTHLY">Monthly</option>
                    </select>
                  </div>
                  <div
                    className="col-md-3"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Data Points: &nbsp;
                    <select
                      className="input-sm"
                      value={dataPointCount}
                      onChange={handleDataPointCountChange}
                    >
                      <option value="6">6</option>
                      <option value="12">12</option>
                      <option value="18">18</option>
                      <option value="24">24</option>
                    </select>
                  </div>
                </div>
                <div
                  className="row"
                  style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                  <div className="col-md-12">
                    <h4 style={{ textAlign: 'center' }}>Net Revenue</h4>
                    <RadarLineChart
                      establishmentData={getReportDataHook.establishmentData}
                      yLabel="Revenue"
                      yLabelSymbol="Dollars"
                      chartCategory={ChartCategoryEnum.REVENUE}
                      reportDataMap={reportMap}
                      periodDuration={periodDuration}
                      establishmentCategory={
                        selectedEstablishment.establishmentType.description
                      }
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{marginTop: '15px', marginBottom: '15px'}}
                >
                  <div className="col-md-12">
                    <h4 style={{textAlign: 'center'}}>Hourly Labor Rate</h4>
                    <RadarLineChart
                      establishmentData={getReportDataHook.establishmentData}
                      yLabel="Hourly Labor Rate (%)"
                      yLabelSymbol="PERCENT"
                      chartCategory={ChartCategoryEnum.LABOR_RATE}
                      reportDataMap={reportMap}
                      periodDuration={periodDuration}
                      establishmentCategory={
                        selectedEstablishment.establishmentType.description
                      }
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{marginTop: '15px', marginBottom: '15px'}}
                >
                  <div className="col-md-12">
                    <h4 style={{textAlign: 'center'}}>Price Per  Order</h4>
                    <RadarLineChart
                      establishmentData={getReportDataHook.establishmentData}
                      yLabel="Price Per Order"
                      yLabelSymbol="Dollars"
                      chartCategory={ChartCategoryEnum.PRICE_PER_ORDER}
                      reportDataMap={reportMap}
                      periodDuration={periodDuration}
                      establishmentCategory={
                        selectedEstablishment.establishmentType.description
                      }
                    />
                  </div>
                </div>
                <div
                  className="row"
                  style={{marginTop: '15px', marginBottom: '15px'}}
                >
                  <div className="col-md-12">
                    <h4 style={{textAlign: 'center'}}>Sale Per Labor Hour</h4>
                    <RadarLineChart
                      establishmentData={getReportDataHook.establishmentData}
                      yLabel="Sales Per Labor Hour"
                      yLabelSymbol="Dollars"
                      chartCategory={ChartCategoryEnum.SALES_PER_LABOR_HOUR}
                      reportDataMap={reportMap}
                      periodDuration={periodDuration}
                      establishmentCategory={
                        selectedEstablishment.establishmentType.description
                      }
                    />
                  </div>
                </div>
                {(() => {
                  if (periodDuration === 'MONTHLY') {
                    return (
                      <div>
                        <div
                          className="row"
                          style={{ marginTop: '15px', marginBottom: '15px' }}
                        >
                          <div className="col-md-12">
                            <h4 style={{ textAlign: 'center' }}>
                              Revenue Per Square Foot
                            </h4>
                            <RadarLineChart
                              establishmentData={
                                getReportDataHook.establishmentData
                              }
                              yLabel="Revenue per Sq Foot (%)"
                              yLabelSymbol="Dollars"
                              chartCategory={
                                ChartCategoryEnum.REVENUE_PER_SQ_FOOT
                              }
                              reportDataMap={reportMap}
                              periodDuration={periodDuration}
                              establishmentCategory={
                                selectedEstablishment.establishmentType
                                  .description
                              }
                            />
                          </div>
                        </div>
                        <div
                          className="row"
                          style={{ marginTop: '15px', marginBottom: '15px' }}
                        >
                          <div className="col-md-12">
                            <h4 style={{ textAlign: 'center' }}>Lease Rate</h4>
                            <RadarLineChart
                              establishmentData={
                                getReportDataHook.establishmentData
                              }
                              yLabel="Lease Rate (%)"
                              yLabelSymbol="PERCENT"
                              chartCategory={ChartCategoryEnum.LEASE_RATE}
                              reportDataMap={reportMap}
                              periodDuration={periodDuration}
                              establishmentCategory={
                                selectedEstablishment.establishmentType
                                  .description
                              }
                            />
                          </div>
                        </div>
                      </div>
                    );
                  }
                  return <span />;
                })()}
              </div>
            );
          }
        } else if (!getReportDataHook.isLoadingReportData) {
          const hasEnteredAnyData = false;

          if (!hasEnteredAnyData) {
            return (
              <div className="section-container-lesspad">
                <div className="container">
                  You have not entered enough data for reports to be generated.
                  In order to get the most out of Radar Report, visit the&nbsp;
                  <Link to="/datacenter">Data Center</Link> and enter your
                  current and historical sales data. Then come back here to the
                  Dashboard to see how you stack up to your peers.
                </div>
              </div>
            );
          }
          return (
            <div className="section-container-lesspad">
              <div className="container">
                The data you have entered is being processed. Check back soon to
                see your reports.
              </div>
            </div>
          );
        }
        return <div />;
      })()}
      <div className="section-container-lesspad">
        <div className="container text-center">
          <i>
            Note: In order for full reports to be generated, the following
            conditions must be met: You set up integration with your POS. You
            have picked a peer group. Other establishments have entered their
            data.
          </i>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
