import { createTheme } from '@mui/material/styles';

export const StepLabelTheme = createTheme({
  components: {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.5rem',
        },
      },
    },
  },
});

export const ToolTipTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.25rem',
        },
      },
    },
  },
});
