import { useState } from 'react';
import useApiCallV2 from './useApiCallV2';
import { ToastConfig, ToastConfigDataResponse } from '../Interfaces';
import { useGlobalProfile } from './useGlobalProfile';

export const useGetToastConfigs = (): ToastConfigDataResponse => {
  const [configDataFetched, setConfigDataFetched] = useState(false);
  const [toastConfigData, setToastConfigData] = useState<ToastConfig[]>([]);
  const [isLoadingConfigData, setIsLoadingConfigData] = useState(true);
  const { hasToken, execute } = useApiCallV2();
  const { profile: globalProfile } = useGlobalProfile();

  const fetchData = async (): Promise<boolean> => {
    if (!hasToken) {
      return false;
    }

    setIsLoadingConfigData(true);

    const response = await execute({
      method: 'GET',
      endpoint: `/api/pos/toast?username=${globalProfile.email}`,
    });

    setToastConfigData(response.data);
    globalProfile.setAvailableToastConfigs(response.data);

    if (response.error) {
      setConfigDataFetched(false);
    } else {
      setConfigDataFetched(true);
    }

    setIsLoadingConfigData(false);
    return true;
  };

  return {
    fetchData,
    configDataFetched,
    isLoadingConfigData,
    toastConfigData,
    hasToken,
  };
};
