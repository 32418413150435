import React from 'react';
import { Tooltip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ToolTipTheme } from '../../themes/MUIThemes';

type SavePeersButtonProps = {
  disabled: boolean;
  savePeers: () => void;
};

function SavePeersButton(
  props: SavePeersButtonProps,
): React.ReactElement | null {
  const { disabled, savePeers } = props;

  if (disabled) {
    return (
      <ThemeProvider theme={ToolTipTheme}>
        <Tooltip
          title="You must select at least 4 peers to save."
          placement="top"
        >
          <span className="peer-remove-button">
            <button type="button" className="btn btn-disabled">
              Save
            </button>
          </span>
        </Tooltip>
      </ThemeProvider>
    );
  }

  return (
    <span className="peer-remove-button">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => savePeers()}
      >
        Save
      </button>
    </span>
  );
}
export default SavePeersButton;
