import { useEffect, useState } from 'react';
import useApiCallV2 from './useApiCallV2';
import { EstTypeOption, UseGetEstTypeOptionsResponse } from '../Interfaces';

export const useGetEstTypeOptions = (): UseGetEstTypeOptionsResponse => {
  const [optionsFetched, setOptionsFetched] = useState(false);
  const [optionsData, setOptionsData] = useState<EstTypeOption[]>([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const { hasToken, execute } = useApiCallV2();

  useEffect(() => {
    if (hasToken && !optionsFetched) {
      setIsLoadingOptions(true);

      const fetchData = async (): Promise<void> => {
        const response = await execute({
          method: 'GET',
          endpoint: '/api/public/establishments/types',
        });
        setOptionsData(response.data);
        setOptionsFetched(true);
        setIsLoadingOptions(false);
      };
      fetchData();
    }
  }, [hasToken]);

  return {
    optionsFetched,
    isLoadingOptions,
    optionsData,
  };
};
