import React, { useRef, useEffect, useState, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import {
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import TapasIcon from '@mui/icons-material/Tapas';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import LocalDining from '@mui/icons-material/LocalDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import { Establishment } from '../Interfaces';
import EstablishmentListItemAction from './Establishments/EstablishmentListItem';
import PeerEstablishmentButton from './Establishments/PeerEstablishmentButton';
import { useGlobalProfile } from '../hooks/useGlobalProfile';
import useApiCall from '../hooks/useApiCall';
import '../css/Establishments.css';
import SavePeersButton from './Establishments/SavePeersButton';
import EditPeersButton from './Establishments/EditPeersButton';

function Establishments(): React.ReactElement {
  const { profile: globalProfile } = useGlobalProfile();
  const googleMapRef = useRef<HTMLDivElement>(null);
  const modalSuccessRef = useRef<HTMLDivElement>(null);
  const modalConfirmRef = useRef<HTMLDivElement>(null);
  const modalBGRef = useRef<HTMLDivElement>(null);
  const listItemRefs = useRef<(HTMLElement | null)[]>([]);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [displaySet, setDisplaySet] = React.useState<Establishment[]>([]);
  const [peerCount, setPeerCount] = React.useState(0);
  const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>();
  const [markers, setMarkers] = React.useState<google.maps.Marker[]>();
  const [selectedIndex, setSelectedIndex] = React.useState(-1);
  const [saveRequestSent, setSaveRequestSent] = React.useState(false);
  const [hasPeerSetSaved, setHasPeerSetSaved] = React.useState(false);
  const [hasPeerSet, setHasPeerSet] = React.useState(false);
  const [establishments, setEstablishments] = React.useState<Establishment[]>(
    [],
  );
  const [activeId, setActiveId] = useState(globalProfile.activeEstablishmentId);
  const [fetched, setFetched] = useState(false);
  const { isLoading, data, error, execute } = useApiCall();
  const {
    isLoading: executingSave,
    // data: saveResponse,
    // error: saveError,
    execute: executeSave,
    hasToken,
  } = useApiCall();
  const hasPosIntegration =
    globalProfile.getActiveEstablishment()?.pointOfSaleIntegration !== 'MANUAL';

  //
  // function: showInfoWindow
  // params:  establishmentIndex - index into array of establishments
  // desc:  Shows info window w/ establishment name on map over specified establishment
  //
  const showInfoWindow = (establishmentIndex: number): void => {
    if (infoWindow && markers) {
      const contentString = `<b>${establishments[establishmentIndex].name}</b>`;
      infoWindow.close();
      infoWindow.setContent(contentString);
      infoWindow.open({
        anchor: markers[establishmentIndex],
        map,
        shouldFocus: false,
      });
    }
  };

  // function: addEstablishmentToPeerList
  // params:  establishmentId - id of est to add
  // desc: Adds establishment to temp peer list to be saved
  //
  const addEstablishmentToPeerList = (establishmentId: number): void => {
    // get the establishment
    const establishmentToAdd = establishments.find(
      est => est.id === establishmentId,
    );
    if (establishmentToAdd && !hasPeerSetSaved && hasPosIntegration) {
      const newDisplaySet = displaySet;
      newDisplaySet.push(establishmentToAdd);
      setPeerCount(peerCount + 1);
      setDisplaySet(newDisplaySet);
      setHasPeerSet(true);
    }
  };

  // function: removeEstablishmentFromPeerGroup
  // params:  establishmentId - id of est to add
  // desc: Removes establishment from temp peer list to be saved
  //
  const removeEstablishmentFromPeerGroup = (establishmentId: number): void => {
    const newDisplaySet = displaySet;
    const indexToRemove = newDisplaySet.findIndex(
      est => est.id === establishmentId,
    );

    if (indexToRemove > -1) {
      newDisplaySet.splice(indexToRemove, 1);
      setDisplaySet(newDisplaySet);

      let newPeerCount = peerCount;
      newPeerCount -= 1;
      if (newPeerCount === 0) {
        setHasPeerSet(false);
      }
      setPeerCount(newPeerCount);
    }
  };

  //
  // function: confirmSaveSelectedPeerSet
  // params:  none
  // desc:  Show confirm modal
  //
  const confirmSaveSelectedPeerSet = (): void => {
    if (modalConfirmRef.current && modalBGRef.current) {
      modalConfirmRef.current.style.display = 'block';
      modalBGRef.current.style.display = 'block';
    }
  };

  const hideConfirmSavePeerGroup = (): void => {
    if (modalConfirmRef.current && modalBGRef.current) {
      modalConfirmRef.current.style.display = 'none';
      modalBGRef.current.style.display = 'none';
    }
  };

  const showSuccessMessage = (): void => {
    if (modalSuccessRef.current && modalBGRef.current) {
      modalSuccessRef.current.style.display = 'block';
      modalBGRef.current.style.display = 'block';
    }
  };

  const hideSuccessMessage = (): void => {
    if (modalSuccessRef.current && modalBGRef.current) {
      modalSuccessRef.current.style.display = 'none';
      modalBGRef.current.style.display = 'none';
    }
  };

  //
  // function: saveSelectedPeerSet
  // params:  none
  // desc:  Calls API to persist the actively selected peer set to DB
  //
  const saveSelectedPeerSet = (): void => {
    hideConfirmSavePeerGroup();

    if (
      hasPeerSet &&
      !hasPeerSetSaved &&
      hasPosIntegration &&
      displaySet.length >= 4
    ) {
      // get peer ids
      const peers: number[] = [];
      displaySet.forEach(peer => {
        peers.push(peer.id);
      });

      if (hasToken) {
        setSaveRequestSent(true);
        executeSave({
          method: 'PUT',
          endpoint: `/api/establishments/${globalProfile.activeEstablishmentId}/peers`,
          requestBody: peers,
        });
      }
    }
  };
  useEffect(() => {
    if (saveRequestSent) {
      if (!executingSave) {
        globalProfile.setComparableGroup(
          globalProfile.activeEstablishmentId,
          displaySet,
        );
        showSuccessMessage();
        setHasPeerSetSaved(true);
      }
    }
  }, [executingSave]);

  //
  // function: handleChangeActiveEstablisment
  // params:  event - select event object
  // desc:  Handles when user selects new active establishment from select box
  //
  const handleChangeActiveEstablisment = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    const pickedEstablishmentId = parseInt(event.target.value, 10);
    globalProfile.activeEstablishmentId = pickedEstablishmentId;
    // reportResults.setFetchId(pickedEstablishmentId);
    // monthlyDataResults.setFetchId(pickedEstablishmentId);
    setActiveId(pickedEstablishmentId);
  };

  //
  // function: handleListItemClick
  // params:  indexClicked - index of the clicked item
  // desc:  Handles a click in the list of establishments. Highlights establishment in the list and centers the map
  //        on the establishment
  //
  const handleListItemClick = (indexClicked: number): void => {
    const latLong = new google.maps.LatLng(
      establishments[indexClicked].latitude,
      establishments[indexClicked].longitude,
    );
    if (map) {
      map.setCenter(latLong);
      map.setZoom(17);
    }
    setSelectedIndex(indexClicked);
  };

  //
  // function: handlePeerClick
  // params:  establishmentId - id of the peer establishment clicked
  // desc:  Handles a click on a peer establishment. Highlights establishment in the list and centers the map
  //        on the establishment
  //
  const handlePeerClick = (establishmentId: number): void => {
    const estIndex = establishments.findIndex(est => {
      return est.id === establishmentId;
    });

    if (estIndex > -1) {
      handleListItemClick(estIndex);
    }
  };

  //
  // function: createGoogleMap
  // params:  none
  // desc:  Called after the google maps script is loaded to instantiate map object into DOM
  //
  const createGoogleMap = (): void => {
    const googleMap = new window.google.maps.Map(
      googleMapRef.current as HTMLDivElement,
      {
        zoom: 12,
        center: {
          lat: 32.7515739,
          lng: -117.08,
        },
      },
    );
    setMap(googleMap);
    setInfoWindow(new window.google.maps.InfoWindow());
  };

  //
  // function: addMapMarkers
  // params:  none
  // desc:  Add markers on the map for each establshment
  //
  const addMapMarkers = (): void => {
    const tmpMarkers: google.maps.Marker[] = [];

    if (!map) {
      return;
    }

    establishments.forEach((establishment, index) => {
      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(
          establishment.latitude,
          establishment.longitude,
        ),
      });
      marker.setMap(map as google.maps.Map);
      google.maps.event.addListener(marker, 'click', () => {
        setSelectedIndex(index);
      });
      tmpMarkers.push(marker);
    });
    setMarkers(tmpMarkers);
  };

  // useEffect for initiating API request and drawing map
  useEffect(() => {
    if (!fetched) {
      setFetched(true);
      execute({
        method: 'GET',
        endpoint: '/api/public/establishments',
        noAuth: true,
      });
    }
    if (fetched && !isLoading) {
      if (!error) {
        setEstablishments(data);
      }
    }

    if (!map) {
      createGoogleMap();
    }
  }, [isLoading]);

  // useEffect for drawing markers after API load, also for
  // showing infoWindow and scrolling list on clicks
  useEffect(() => {
    if (fetched) {
      if (!markers) {
        addMapMarkers();
      }
      if (selectedIndex !== -1) {
        showInfoWindow(selectedIndex);
        listItemRefs?.current[selectedIndex]?.scrollIntoView();
      }
    }
  }, [selectedIndex, establishments]);

  useEffect(() => {
    const activeEstablishment = globalProfile.getActiveEstablishment();
    if (
      activeEstablishment?.competitiveSet &&
      activeEstablishment.competitiveSet.length >= 4
    ) {
      setHasPeerSetSaved(true);
      setHasPeerSet(true);
      setDisplaySet(activeEstablishment.competitiveSet);
      setPeerCount(activeEstablishment.competitiveSet.length);
    } else {
      setHasPeerSetSaved(false);
      setHasPeerSet(false);
      setDisplaySet([]);
      setPeerCount(0);
    }
  }, [activeId]);

  return (
    <div className="section-container-lesspad">
      <div className="modal-backdrop" ref={modalBGRef} />
      <div className="modal" ref={modalSuccessRef}>
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Group Saved</h4>
              <button
                type="button"
                className="close"
                onClick={hideSuccessMessage}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-sm-12">
                You have successfully saved your peer group. Data is being
                processed. You can soon view reports in the &nbsp;
                <Link to="/">Dashboard</Link>.
              </div>
              <div className="col-sm-12 text-right">
                <button
                  type="button"
                  className="btn"
                  onClick={hideSuccessMessage}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal" ref={modalConfirmRef}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Confim Save</h4>
              <button
                type="button"
                className="close"
                onClick={hideConfirmSavePeerGroup}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-sm-12">
                Are you sure you want to save this peer group? You will need to
                contact support to change your peer group.
              </div>
              <div className="col-sm-12 text-right">
                <br />
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveSelectedPeerSet}
                >
                  Save
                </button>
                <button
                  type="button"
                  className="btn"
                  onClick={hideConfirmSavePeerGroup}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {(() => {
          if (executingSave) {
            return (
              <div id="modal-container" style={{ top: '140px' }}>
                <div className="alert alert-info">
                  <span
                    className="spinner-border spinner-border-md"
                    role="status"
                  />
                  <div>Saving Comparable Group</div>
                </div>
              </div>
            );
          }
          return '';
        })()}
        <div className="row">
          <div className="col-md-6">
            <h3 className="page-title">Establishments</h3>
          </div>
          <div className="col-md-6 text-right">
            {(() => {
              if (globalProfile.establishments.length === 1) {
                return globalProfile.establishments[0].name;
              }
              return (
                <select
                  className="form-control"
                  value={activeId}
                  onChange={handleChangeActiveEstablisment}
                  style={{
                    width: '350px',
                    float: 'right',
                    position: 'relative',
                    top: '-5px',
                  }}
                >
                  {globalProfile.establishments.map(establishment => (
                    <option key={establishment.id} value={establishment.id}>
                      {establishment.name}
                    </option>
                  ))}
                </select>
              );
            })()}
          </div>
        </div>
        <Divider />
        <div className="container">
          <div className="row">
            <div className="col-sm-3 comp-group-title text-right">
              <h4>Your Comparable Group</h4>
            </div>
            <div className="col-sm-8">
              {(() => {
                if (!hasPosIntegration) {
                  return (
                    <div className="select-peer-display">
                      <i>
                        You must set up Toast integration before you can select
                        a peer group.
                      </i>
                    </div>
                  );
                }
                if (!hasPeerSet) {
                  return (
                    <div className="select-peer-display">
                      <i>
                        Select at least 4 Peers to compare yourself to from the
                        map below. (You will only be able to update this list
                        under certain circumstances)
                      </i>
                    </div>
                  );
                }
                const setDivsArr: ReactElement[] = [];
                displaySet.forEach(est => {
                  setDivsArr.push(
                    <PeerEstablishmentButton
                      key={`${est.id}-peb`}
                      establishment={est}
                      hasPeerSetSaved={hasPeerSetSaved}
                      focusPeerFunction={handlePeerClick}
                      removePeerFunction={removeEstablishmentFromPeerGroup}
                    />,
                  );
                });
                return <div className="peer-display">{setDivsArr}</div>;
              })()}
            </div>
            <div className="col-sm-1">
              <div className="peer-display">
                {(() => {
                  if (hasPeerSet && !hasPeerSetSaved) {
                    let disableButton = false;
                    if (displaySet.length < 4 || executingSave) {
                      disableButton = true;
                    }
                    return (
                      <SavePeersButton
                        disabled={disableButton}
                        savePeers={confirmSaveSelectedPeerSet}
                      />
                    );
                  }
                  if (hasPeerSetSaved) {
                    return <EditPeersButton />;
                  }
                  return <span />;
                })()}
              </div>
            </div>
          </div>
        </div>
        <div id="list-map">
          <div id="establishment-list">
            <List
              sx={{ width: '100%', maxWidth: 300, bgcolor: 'background.paper' }}
            >
              {establishments.map((establishment, index) => (
                <ListItem
                  disablePadding
                  key={establishment.id}
                  ref={ref => {
                    listItemRefs.current.push(ref);
                  }}
                  secondaryAction={
                    <EstablishmentListItemAction
                      establishmentId={establishment.id}
                      showAddButton={
                        establishment.id !==
                          globalProfile.activeEstablishmentId &&
                        !hasPeerSetSaved &&
                        hasPosIntegration &&
                        displaySet.findIndex(
                          est => est.id === establishment.id,
                        ) === -1
                      }
                      addEstablishment={addEstablishmentToPeerList}
                    />
                  }
                >
                  <ListItemButton
                    selected={selectedIndex === index}
                    onClick={() => handleListItemClick(index)}
                  >
                    <ListItemAvatar>
                      {(() => {
                        switch (establishment.establishmentType.code) {
                          case 'FAST_CASUAL':
                            return <DinnerDiningIcon />;
                          case 'BAR_AND_GRILL':
                            return <TapasIcon />;
                          case 'BAR_ONLY':
                            return <LocalBarIcon />;
                          case 'FINE_DINING':
                            return <RestaurantIcon />;
                          default:
                            return <LocalDining />;
                        }
                      })()}
                    </ListItemAvatar>
                    <ListItemText
                      primary={establishment.name}
                      primaryTypographyProps={{ fontSize: '1em' }}
                      secondary={
                        <>
                          {establishment.address1}
                          <br />
                          {establishment.city}, {establishment.state}
                          {establishment.zip}
                        </>
                      }
                      secondaryTypographyProps={{ fontSize: '.9em' }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>
          <div id="google-map" ref={googleMapRef} />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h4>
                <Link to="/suggest">
                  Can&apos;t find who you&apos;re looking for? Click Here.
                </Link>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Establishments;
