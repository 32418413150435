import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { apiReturnObject, RequestInfo, UseAPIV2Return } from '../Interfaces';

const useApiCallV2 = (): UseAPIV2Return => {
  const [hasToken, setHasToken] = useState(false);
  const [token, setToken] = useState('');
  const { getAccessTokenSilently } = useAuth0();

  // Make API request
  const execute = async (
    requestParams: RequestInfo,
  ): Promise<apiReturnObject> => {
    const returnValue: apiReturnObject = {
      data: null,
      error: null,
    };

    const reqHeaders = new Headers({
      'Content-Type': 'application/json',
    });

    if (!requestParams.noAuth) {
      reqHeaders.append('Authorization', `Bearer ${token}`);
    }

    const requestOptions: RequestInit = {
      method: requestParams.method,
      headers: reqHeaders,
    };

    if (requestParams.requestBody) {
      requestOptions.body = JSON.stringify(requestParams.requestBody);
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_SERVER_PREFIX + requestParams.endpoint,
        requestOptions,
      );

      if (requestParams.textResponse) {
        returnValue.data = { text: await response.text() };
      } else {
        returnValue.data = await response.json();
      }
    } catch (err) {
      if (err instanceof Error) {
        returnValue.error = err;
      }
    }
    return returnValue;
  };

  // Fetch token for API calls
  const fetchToken = async (): Promise<boolean> => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      setToken(accessToken);
      setHasToken(true);
    } catch (err) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (token === '') {
      fetchToken();
    }
  }, []);

  return { hasToken, execute };
};

export default useApiCallV2;
