import React, { useEffect, useState } from 'react';
import useApiCall from '../../hooks/useApiCall';
import FormErrorsBox from '../FormErrorsBox';
import { useGlobalProfile } from '../../hooks/useGlobalProfile';
import '../../css/ToastDataImportSettings.css';

type ToastDataImportSettingsProps = {
  editId: number;
  handleHideEditDataFeedPopup: () => void;
};

type DataImportFormErrorClasses = {
  posRestaurantId: string;
};

function ToastDataImportSettings(
  props: ToastDataImportSettingsProps,
): React.ReactElement {
  const { editId, handleHideEditDataFeedPopup } = props;
  const { profile: globalProfile } = useGlobalProfile();
  const [integrationStatus, setIntegrationStatus] = useState('');
  const [showLoading, setShowLoading] = useState(false);
  const { hasToken, isLoading, data, error, execute } = useApiCall();
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [disableInputs, setDisableInputs] = useState(
    globalProfile.getEstablishmentWithId(editId)?.pointOfSaleIntegration ===
      'MANUAL',
  );
  const [saveRequestSent, setSaveRequestSent] = useState(false);
  const [isToastEnabled, setIsToastEnabled] = useState(
    globalProfile.getEstablishmentWithId(editId)?.pointOfSaleIntegration ===
      'TOAST',
  );
  const [fetched, setFetched] = useState(false);
  const [hideErrorBox, setHideErrorBox] = useState('hide');
  const [isSaving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState({
    toastConfigId: globalProfile.toastConfigs[0]?.id || 0,
    posRestaurantId: '',
  });
  const [errorClass, setErrorClass] = useState<DataImportFormErrorClasses>({
    posRestaurantId: '',
  });
  const {
    isLoading: executingSave,
    // data: saveResponse,
    // error: saveError,
    execute: executeSave,
    hasToken: hasSaveToken,
  } = useApiCall();

  const handleCheckBoxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setIsToastEnabled(event.target.checked);
    setDisableInputs(!event.target.checked);
  };

  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleCancel = (): void => {
    handleHideEditDataFeedPopup();
  };

  // TODO: Need updates here to how we save the establishment level
  // toast config settings
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    // BEGIN form data validation
    let errorsFound = false;
    const localErrorArr: string[] = [];

    const localErrorClasses: DataImportFormErrorClasses = {
      posRestaurantId: '',
    };

    const errorMessages = {
      posRestaurantId: 'Toast Restauarant ID is required and must be digits.',
    };

    const formFieldRegexes = {
      posRestaurantId: /^\d+$/,
    };

    if (isToastEnabled) {
      Object.keys(formFieldRegexes).forEach(formItem => {
        const formValue = (formData[formItem as keyof unknown] as string) || '';
        if (
          !formValue
            .toString()
            .match(formFieldRegexes[formItem as keyof unknown])
        ) {
          errorsFound = true;
          localErrorArr.push(errorMessages[formItem as keyof unknown]);
          localErrorClasses[formItem as keyof DataImportFormErrorClasses] =
            'has-error';
        }
      });

      if (errorsFound) {
        setHideErrorBox('');
      } else {
        setHideErrorBox('hide');
      }
      setErrorClass({ ...errorClass, ...localErrorClasses });
      setFormErrors(localErrorArr);
      // END form data validation
    }

    if (!hasSaveToken || errorsFound) {
      // we don't meet the conditions to send API request
      return;
    }

    if (
      !isToastEnabled &&
      globalProfile.getEstablishmentWithId(editId)?.pointOfSaleIntegration ===
        'MANUAL'
    ) {
      return;
    }

    // if we got here we are going to send API request...let's get it ready
    setIsSaving(true);

    const requestBody = {
      posRestaurantId: formData.posRestaurantId,
      disabled: !isToastEnabled,
    };

    setSaveRequestSent(true);
    executeSave({
      method: 'PUT',
      endpoint: `/api/pos/toast/${formData.toastConfigId}/establishment/${editId}`,
      requestBody,
    });
  };
  useEffect(() => {
    if (saveRequestSent) {
      if (!executingSave) {
        setIsSaving(false);
        const dataSoureType = isToastEnabled ? 'TOAST' : 'MANUAL';
        globalProfile.setEstablishmentDataSource(editId, dataSoureType);
        handleHideEditDataFeedPopup();
      }
    }
  }, [executingSave]);

  // useEffect for initiating API request
  useEffect(() => {
    if (
      hasToken &&
      !fetched &&
      globalProfile.getEstablishmentWithId(editId)?.pointOfSaleIntegration ===
        'TOAST'
    ) {
      setShowLoading(true);
      setFetched(true);
      execute({
        method: 'GET',
        endpoint: `/api/pos/toast/establishment/${editId}`,
      });
    }
    if (fetched && !isLoading) {
      setShowLoading(false);
      if (!error) {
        setFormData({
          posRestaurantId: data.posRestaurantId,
          toastConfigId: data.config.id,
        });
        setIntegrationStatus(data.status);
      }
    }
  }, [isLoading, hasToken]);

  return (
    <div className="container">
      {(() => {
        if (showLoading) {
          return (
            <div id="modal-container">
              <div className="alert alert-info">
                <span
                  className="spinner-border spinner-border-md"
                  role="status"
                  aria-hidden="true"
                />
                <div>Loading Saved Import Configuration</div>
              </div>
            </div>
          );
        }
        return '';
      })()}
      <br />
      <FormErrorsBox formErrors={formErrors} hideErrorBoxClass={hideErrorBox} />
      <form onSubmit={handleFormSubmit}>
        <div className="form-group row">
          <div className="col-sm-2" />
          <div className="col-sm-10">
            <input
              type="checkbox"
              id="enableToast"
              checked={isToastEnabled}
              onChange={handleCheckBoxChange}
            />
            &nbsp;
            <label className="control-label" htmlFor="flexSwitchCheckDefault">
              Enabled
            </label>
          </div>
        </div>
        <div className={`form-group row ${errorClass.posRestaurantId}`}>
          <label htmlFor="posRestaurantId" className="col-sm-2 col-form-label">
            Toast Configuration
          </label>
          <div className="col-sm-10">
            <select
              className="form-control"
              name="toastConfigId"
              disabled={disableInputs}
              value={formData.toastConfigId}
              onChange={event => {
                setFormData({
                  ...formData,
                  [event.target.name]: event.target.value,
                });
              }}
            >
              {globalProfile.toastConfigs.map(config => (
                <option key={config.id} value={config.id}>
                  {config.configName}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={`form-group row ${errorClass.posRestaurantId}`}>
          <label htmlFor="posRestaurantId" className="col-sm-2 col-form-label">
            Toast Restaurant ID
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="posRestaurantId"
              name="posRestaurantId"
              placeholder="Toast Restaurant ID"
              value={formData.posRestaurantId}
              disabled={disableInputs}
              onChange={handleFormChange}
            />
          </div>
        </div>
        {(() => {
          if (globalProfile.toastConfigs.length <= 0)
            return (
              <div className="form-group row">
                <label className="col-sm-2 col-form-label" />
                <div className="col-sm-10">
                  <b>
                    You must create a Toast Import Configuration before you can
                    enable Toast as a data source.
                  </b>
                </div>
              </div>
            );

          return <span />;
        })()}
        {(() => {
          if (integrationStatus !== '') {
            return (
              <div className="form-group row">
                <label className="col-sm-2 col-form-label">
                  Last Import Status:
                </label>
                <div className="col-sm-10 tdis-status-div">
                  {integrationStatus}
                </div>
              </div>
            );
          }
          return '';
        })()}
        <div className="form-group row">
          <div className="col-sm-12 text-right">
            <span>
              <button
                onClick={handleCancel}
                type="button"
                disabled={isSaving}
                className="btn"
              >
                <div
                  style={{
                    float: 'right',
                    paddingTop: '2px',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                  }}
                >
                  Cancel
                </div>
              </button>
              &nbsp;
            </span>
            <button
              type="submit"
              disabled={isSaving || globalProfile.toastConfigs.length <= 0}
              className="btn btn-primary"
            >
              {(() => {
                if (isSaving) {
                  return (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <div
                        style={{
                          float: 'right',
                          paddingTop: '2px',
                          paddingLeft: '4px',
                        }}
                      >
                        &nbsp;Saving...
                      </div>
                    </span>
                  );
                }

                return (
                  <div
                    style={{
                      float: 'right',
                      paddingTop: '2px',
                      paddingLeft: '4px',
                      paddingRight: '4px',
                    }}
                  >
                    &nbsp;Save
                  </div>
                );
              })()}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ToastDataImportSettings;
