import {
  Establishment,
  EstablishmentData,
  Subscription,
  ToastConfig,
} from '../Interfaces';

// export interface ProfileInterface {
//   firstName?: string;
//   lastName?: string;
//   phoneNumber?: string;
//   email: string;
//   id?: number;
//   establishments?: [Establishment];
//   hasFullProfile: () => boolean;
//   addEstablishment: (establishment: Establishment) => void;
// }

class Profile {
  firstName = '';

  lastName = '';

  phoneNumber = 0;

  email = '';

  id = 0;

  activeEstablishmentId = 0;

  tosAccepted = false;

  establishments: Establishment[] = [];

  toastConfigs: ToastConfig[] = [];

  // constructor() {

  // }

  isActiveEstablishmentPremium(): boolean {
    const activeEst = this.establishments.find(
      est => est.id === this.activeEstablishmentId,
    );

    if (activeEst && activeEst.subscriptionType === 'PREMIUM') {
      return true;
    }

    return false;
  }

  getActiveEstablishmentLeaseRate(): number {
    const activeEst = this.establishments.find(
      est => est.id === this.activeEstablishmentId,
    );

    if (activeEst && activeEst.leaseAmount) {
      return parseInt(activeEst.leaseAmount, 10) || 0;
    }

    return 0;
  }

  getActiveEstablishment(): Establishment | undefined {
    return this.establishments.find(
      est => est.id === this.activeEstablishmentId,
    );
  }

  getActiveEstablishmentData(): EstablishmentData[] {
    const activeEst = this.establishments.find(
      est => est.id === this.activeEstablishmentId,
    );

    return activeEst?.monthlyData || [];
  }

  getEstablishmentWithId(establishmentId: number): Establishment | undefined {
    const targetEst = this.establishments.find(
      est => est.id === establishmentId,
    );
    return targetEst;
  }

  setEstablishmentDataSource(
    establishmentId: number,
    dataSourceType: string,
  ): void {
    for (let i = 0; i < this.establishments.length; i += 1) {
      if (this.establishments[i].id === establishmentId) {
        this.establishments[i].pointOfSaleIntegration = dataSourceType;
      }
    }
  }

  setActiveEstablishmentData(data: EstablishmentData[]): void {
    const activeEst = this.establishments.find(
      est => est.id === this.activeEstablishmentId,
    );

    if (activeEst) {
      activeEst.monthlyData = data;
    }
  }

  setAvailableToastConfigs(configs: ToastConfig[]): void {
    this.toastConfigs = configs;
  }

  getActiveEstablishmentSquareFootage(): number {
    const activeEst = this.establishments.find(
      est => est.id === this.activeEstablishmentId,
    );

    if (activeEst && activeEst.squareFootage) {
      return parseInt(activeEst.squareFootage, 10) || 0;
    }

    return 0;
  }

  setComparableGroup(
    establishmentId: number,
    compGroup: Establishment[],
  ): void {
    for (let i = 0; i < this.establishments.length; i += 1) {
      if (this.establishments[i].id === establishmentId) {
        this.establishments[i].competitiveSet = compGroup;
      }
    }
  }

  setEstablishmentSubscription(
    establishmentId: number,
    sub: Subscription,
  ): void {
    for (let i = 0; i < this.establishments.length; i += 1) {
      if (this.establishments[i].id === establishmentId) {
        this.establishments[i].subscriptions = [sub];

        if (sub.status === 'ACTIVE') {
          this.establishments[i].subscriptionType = 'PREMIUM';
        }
      }
    }
  }

  sortEstablishments(): void {
    this.establishments.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }

  hasFullProfile(): boolean {
    if (this.establishments.length > 0) {
      return true;
    }
    return false;
  }

  hasProfile(): boolean {
    if (this.id !== 0) {
      return true;
    }
    return false;
  }

  addEstablishment(establishment: Establishment): void {
    this.establishments.push(establishment);
  }
}

export default Profile;
