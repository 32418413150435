import React, { useEffect, useRef, useState } from 'react';
import { Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ToastConfigForm from './ToastConfigForm';
import { useGetToastConfigs } from '../../hooks/useGetToastConfigs';
import { ToastConfig } from '../../Interfaces';
import '../../css/ToastDataImportSettings.css';

function DataImportConfigTable(): React.ReactElement {
  const [popupFormMode, setPopupFormMode] = useState('');
  const [successFadeClass, setSuccessFadeClass] = useState('');
  const [editData, setEditData] = useState<ToastConfig>({
    id: 0,
    configName: '',
    sftpUrl: '',
    sftpUser: '',
  });
  const getToastConfigsHook = useGetToastConfigs();
  const modalFormRef = useRef<HTMLDivElement>(null);
  const modalBGRef = useRef<HTMLDivElement>(null);

  const handleCopyKeyToClipboard = (key: string): void => {
    navigator.clipboard.writeText(key);
    setSuccessFadeClass('tdis_fade_in_image');
    setTimeout(() => setSuccessFadeClass(''), 5050);
  };

  const handleOpenFormModal = (): void => {
    if (modalFormRef.current && modalBGRef.current) {
      modalFormRef.current.style.display = 'block';
      modalBGRef.current.style.display = 'block';
    }
  };

  const handleCloseFormModal = (): void => {
    if (modalFormRef.current && modalBGRef.current) {
      modalFormRef.current.style.display = 'none';
      modalBGRef.current.style.display = 'none';
    }
  };

  const handleConfigSaved = (): void => {
    handleCloseFormModal();
    getToastConfigsHook.fetchData();
  };

  const handleAddConfigClicked = (
    event: React.FormEvent<HTMLFormElement>,
  ): void => {
    event.preventDefault();
    setPopupFormMode('ADD');
    setEditData({
      id: 0,
      configName: '',
      sftpUrl: '',
      sftpUser: '',
    });
    handleOpenFormModal();
  };

  const handleEditConfigClicked = (config: ToastConfig): void => {
    setPopupFormMode('EDIT');
    setEditData(config);
    handleOpenFormModal();
  };

  useEffect(() => {
    if (!getToastConfigsHook.configDataFetched) {
      getToastConfigsHook.fetchData();
    }
  }, [getToastConfigsHook.hasToken]);

  return (
    <div className="container">
      {/* BEGIN: Form Modal */}
      <div className="modal-backdrop" ref={modalBGRef} />
      <div className="modal" ref={modalFormRef}>
        <div className="modal-dialog modal-dialog-wide modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Edit Toast Import Configuration
              </h4>
              <button
                type="button"
                className="close"
                onClick={handleCloseFormModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="col-sm-12">
                <ToastConfigForm
                  formMode={popupFormMode}
                  editData={editData}
                  formDone={handleCloseFormModal}
                  formSaved={handleConfigSaved}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* END: Form Modal */}
      <h3 className="page-title">Your Toast&reg; Import Configs</h3>
      <Divider />
      <br />
      {(() => {
        if (!getToastConfigsHook.configDataFetched) {
          return (
            <div id="modal-container">
              <div className="alert alert-info">
                <span
                  className="spinner-border spinner-border-md"
                  role="status"
                  aria-hidden="true"
                />
                <div>Loading Config Data...</div>
              </div>
            </div>
          );
        }
        if (getToastConfigsHook.toastConfigData.length === 0) {
          return (
            <div className="section-container-lesspad">
              <div className="container">
                You have not created any import configurations.
              </div>
            </div>
          );
        }

        // This was used to remove duplicates from array of toast configs
        // const toastConfigArray = getToastConfigsHook.toastConfigData.filter(
        //   (value, index, array) => {
        //     return array.map(e => e.id).indexOf(value.id) === index;
        //   },
        // );

        return (
          <div>
            <div
              className={`alert alert-success tdis_alert ${successFadeClass}`}
            >
              Public Key Copied to Clipboard
            </div>
            <table className="table table-hover table-bordered">
              <thead className="thead-light">
                <tr>
                  <th className="active" scope="col">
                    Config Name
                  </th>
                  <th className="active" scope="col">
                    SFTP URL
                  </th>
                  <th className="active" scope="col">
                    SFTP Username
                  </th>
                  <th className="active" scope="col">
                    Public Key
                  </th>
                  <th className="active" scope="col">
                    {}
                  </th>
                </tr>
              </thead>
              <tbody>
                {getToastConfigsHook.toastConfigData.map(config => (
                  <tr key={config.id}>
                    <td className="text-left">{config.configName}</td>
                    <td className="text-left">{config.sftpUrl}</td>
                    <td className="text-left">{config.sftpUser}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        title="Copy to Clipboard"
                        onClick={() =>
                          handleCopyKeyToClipboard(config.pubKey || '')
                        }
                      >
                        <div className="icon-button-div">
                          <ContentCopyIcon />
                        </div>
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        title="Edit"
                        onClick={() => handleEditConfigClicked(config)}
                      >
                        <div className="icon-button-div">
                          <EditIcon />
                        </div>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      })()}
      <form onSubmit={handleAddConfigClicked}>
        <div className="form-group row">
          <div className="col-sm-12 text-right">
            <button type="submit" className="btn btn-primary">
              <div
                style={{
                  float: 'right',
                  paddingTop: '2px',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                }}
              >
                &nbsp;Add Toast Import Config
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
export default DataImportConfigTable;
