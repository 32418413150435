import React from 'react';
import ReportIcon from '@mui/icons-material/Report';

type FormErrorsBoxProps = {
  formErrors: string[];
  hideErrorBoxClass: string;
};

function FormErrorsBox(props: FormErrorsBoxProps): React.ReactElement | null {
  const { formErrors, hideErrorBoxClass } = props;

  return (
    <div className={`alert alert-danger ${hideErrorBoxClass}`}>
      <h4 className="alert-heading">
        <div
          style={{
            float: 'left',
            position: 'relative',
            top: '-1px',
            paddingRight: '4px',
          }}
        >
          <ReportIcon fontSize="large" />
        </div>
        There are problems with your inputs:
      </h4>
      {formErrors.map(errorString => (
        <p key={errorString.substring(0, 2)}>{errorString}</p>
      ))}
      <hr />
      <p>
        Once you have fixed everything, click on the Save button to continue.
      </p>
    </div>
  );
}

export default FormErrorsBox;
