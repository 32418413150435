import {EstablishmentData, ReportData} from '../Interfaces';
import {ChartCategoryEnum} from '../models/ChartCategoryEnum';

export function processedReportValueMap(
  reportData: ReportData,
  category: ChartCategoryEnum,
): number {
  return category === ChartCategoryEnum.LEASE_RATE
    ? reportData.averageLeaseRate
    : category === ChartCategoryEnum.REVENUE_PER_SQ_FOOT
    ? reportData.averageRevenuePerFoot
    : category === ChartCategoryEnum.LABOR_RATE ? reportData.averageLaborRate
    : category === ChartCategoryEnum.PRICE_PER_ORDER ? reportData.averagePricePerOrder
    : category === ChartCategoryEnum.SALES_PER_LABOR_HOUR ? reportData.averageSalesPerLaborHour
    : reportData.averageSales;
}

export function establishmentDataValueMap(
  reportData: EstablishmentData,
  category: ChartCategoryEnum,
): number {
  return category === ChartCategoryEnum.LEASE_RATE
    ? reportData.leaseRate
    : category === ChartCategoryEnum.REVENUE_PER_SQ_FOOT
    ? reportData.revenuePerSquareFoot
      : category === ChartCategoryEnum.LABOR_RATE ? reportData.laborRate
      : category === ChartCategoryEnum.PRICE_PER_ORDER ? reportData.pricePerOrder
      : category === ChartCategoryEnum.SALES_PER_LABOR_HOUR ? reportData.salesPerLaborHour
    : reportData.totalSales;
}

/**
 * Utility mehtod to find a record in the processed report data that matches
 * the year and month of the establishment data
 *
 * @param establishmentData establishments monthly data entry
 * @param processedData array of processed report data
 */
export function findMatchingReportDataEntry(
  establishmentData: EstablishmentData,
  processedData: ReportData[],
): ReportData | undefined {
  return processedData.find(
    (data: ReportData) =>
      data.year === establishmentData?.year &&
      data.month === establishmentData.month,
  );
}

export function findLatestMonthsData(
  establishmentData: EstablishmentData[],
): EstablishmentData {
  const latestYearData = establishmentData.reduce((prev, current) =>
    prev.year > current.year ? prev : current,
  );
  // return the latest months data for the establishment
  const latestMontEstData = establishmentData
    .filter(data => data.year === latestYearData?.year)
    .reduce((prev, current) => (prev.month > current.month ? prev : current));
  return latestMontEstData;
}

export function getSortedReportData(
  establishmentData: EstablishmentData[] | undefined,
): EstablishmentData[] {
  if (!establishmentData) return [];
  return establishmentData.sort((b, a) => {
    if (a.year < b.year) return 1;
    if (a.year > b.year) return -1;
    if (a.month < b.month) return 1;
    if (a.month > b.month) return -1;
    return (b.day || 0) - (a.day || 0);
  });
}

/**
 * Helper function to create BarChart Entries for the establishments monthly data and the corresponding
 * report comparison data
 *
 * @param estCategory     defines the establishiments category (BAR, BAR_GRILL....etc)
 * @param chartCagetory   defines the category for the chart (revenue, rev persq foot, lease rate)
 * @param monthlyData     monthly data for the establishment
 * @param latestAll       latest entry for processed data for ALL estab data
 * @param latestCategory  latest processed data entry  based on estblishment category,
 *                         (will be undefined for FREE subscriptions)
 * @param latestPeer      latest processed data entry  based on estblishment competitive peer set,
 *                        (will be undefined for FREE subscriptions)
 */
export function createBarchartEntries(
  estCategory: string,
  chartCagetory: ChartCategoryEnum,
  monthlyData: EstablishmentData,
  latestAll: ReportData,
  latestCategory?: ReportData,
  latestPeer?: ReportData,
): { subject: string; value: number }[] {
  const barChartDataMap = [
    {
      subject: 'YOU',
      value: establishmentDataValueMap(monthlyData, chartCagetory),
      percentReported: monthlyData.percentReported,
    },
    {
      subject: estCategory,
      value: latestCategory
        ? processedReportValueMap(latestCategory, chartCagetory)
        : 0,
      percentReported: latestCategory ? latestCategory?.percentReported : 0,
    },
    {
      subject: 'PEER',
      value: latestPeer
        ? processedReportValueMap(latestPeer, chartCagetory)
        : 0,
      percentReported: latestPeer ? latestPeer.percentReported : 0,
    },
    {
      subject: 'ALL',
      value: processedReportValueMap(latestAll, chartCagetory),
      percentReported: latestAll.percentReported,
    },
  ];
  return barChartDataMap;
}

export function createLineChartEntries(
  establishmentData: EstablishmentData[],
  chartCategory: ChartCategoryEnum,
  reportMap: { [subject: string]: ReportData[] },
): {
  date?: Date;
  YOU?: number;
  ALL?: number;
  CATEGORY?: number;
  PEER?: number;
}[] {
  const chartLineData: {
    date?: Date;
    YOU?: number;
    ALL?: number;
    CATEGORY?: number;
    PEER?: number;
  }[] = [];
  const sortedData = getSortedReportData(establishmentData);

  sortedData?.forEach(establishmenDataPoint => {
    const allEntry = reportMap.ALL.find(
      data =>
        data.year === establishmenDataPoint?.year &&
        data.month === establishmenDataPoint.month &&
        data.day === establishmenDataPoint.day,
    );
    const peerEntry = reportMap.PEER.find(
      data =>
        data.year === establishmenDataPoint?.year &&
        data.month === establishmenDataPoint.month &&
        data.day === establishmenDataPoint.day,
    );
    const categoryEntry = reportMap.CATEGORY.find(
      data =>
        data.year === establishmenDataPoint?.year &&
        data.month === establishmenDataPoint.month &&
        data.day === establishmenDataPoint.day,
    );

    chartLineData.push({
      date: new Date(
        establishmenDataPoint.year,
        establishmenDataPoint.month - 1,
        establishmenDataPoint.day || 1,
      ),
      ALL: allEntry
        ? processedReportValueMap(allEntry, chartCategory)
        : undefined,
      CATEGORY: categoryEntry
        ? processedReportValueMap(categoryEntry, chartCategory)
        : undefined,
      PEER: peerEntry
        ? processedReportValueMap(peerEntry, chartCategory)
        : undefined,
      YOU: establishmenDataPoint
        ? establishmentDataValueMap(establishmenDataPoint, chartCategory)
        : undefined,
    });
  });
  return chartLineData;
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const abbrDayNames = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

export function formatDate(date: Date): string {
  return (date.getMonth() + 1)
    .toString()
    .concat('/')
    .concat(date.getFullYear().toString());
}

export function formatToolTipDate(date: Date, duration: string): string {
  if (!date.getMonth) return '';

  if (duration === 'MONTHLY') {
    return monthNames[date.getMonth()]
      .concat(' ')
      .concat(date.getFullYear().toString());
  }
  if (duration === 'DAILY') {
    return abbrDayNames[date.getDay()]
      .concat(' ')
      .concat((date.getMonth() + 1).toString())
      .concat('/')
      .concat(date.getDate().toString())
      .concat('/')
      .concat(date.getFullYear().toString());
  }
  if (duration === 'WEEKLY') {
    return 'Week Ending on '
      .concat((date.getMonth() + 1).toString())
      .concat('/')
      .concat(date.getDate().toString())
      .concat('/')
      .concat(date.getFullYear().toString().substring(2));
  }
  return '';
}

export function formatReportHeadingDate(year: number, month: number): string {
  return monthNames[month - 1].concat(' ').concat(year.toString());
}

export function formatDateMon(date: Date): string {
  return monthNames[date.getMonth()]
    .substring(0, 3)
    .toUpperCase()
    .concat(' ')
    .concat(date.getFullYear().toString().substring(2));
}

export function formatLineChartTick(date: Date, duration: string): string {
  if (duration === 'MONTHLY') {
    return monthNames[date.getMonth()]
      .substring(0, 3)
      .toUpperCase()
      .concat(' ')
      .concat(date.getFullYear().toString().substring(2));
  }
  if (duration === 'WEEKLY') {
    return (date.getMonth() + 1)
      .toString()
      .concat('/')
      .concat(date.getDate().toString())
      .concat('/')
      .concat(date.getFullYear().toString().substring(2));
  }
  if (duration === 'DAILY') {
    return abbrDayNames[date.getDay()]
      .concat(' ')
      .concat((date.getMonth() + 1).toString())
      .concat('/')
      .concat(date.getDate().toString());
  }
  return duration;
}
