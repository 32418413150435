import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import FormErrorsBox from './FormErrorsBox';
import useApiCall from '../hooks/useApiCall';
import { useGlobalProfile } from '../hooks/useGlobalProfile';

type BillingFormErrorClasses = {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  cardNumber: string;
  cardCode: string;
};

type formDataObj = {
  firstName: string;
  lastName: string;
  address1: string;
  address2: string;
  city: string;
  zip: string;
  cardNumber: string;
  cardCode: string;
  expirationMonth: string;
  expirationYear: string;
};

function BillingSubscription(): React.ReactElement {
  const { hasToken, isLoading, data, execute } = useApiCall();
  const { profile: globalProfile } = useGlobalProfile();
  const [searchParams] = useSearchParams();
  const nav = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [isOnboarding] = useState(searchParams.get('onboarding') === '1');
  const [savedEstId, setSavedEstId] = useState(0);
  const [hideErrorBox, setHideErrorBox] = useState('hide');
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [formData, setFormData] = useState({
    firstName: globalProfile.firstName,
    lastName: globalProfile.lastName,
    address1: '',
    address2: '',
    city: '',
    zip: '',
    state: 'CA',
    cardNumber: '',
    cardCode: '',
    expirationMonth: '1',
    expirationYear: '2024',
    promoCode: '',
  });
  const [errorClass, setErrorClass] = useState<BillingFormErrorClasses>({
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    zip: '',
    cardNumber: '',
    cardCode: '',
  });

  // define the fields to be used to generate the billing info form
  const formFields = [
    {
      id: 'firstName',
      label: 'First Name',
      placeHolder: 'First Name',
    },
    {
      id: 'lastName',
      label: 'Last Name',
      placeHolder: 'Last Name',
    },
    {
      id: 'address1',
      label: 'Billing Address',
      placeHolder: 'Billing Address',
    },
    {
      id: 'address2',
      label: 'Billing Address 2',
      placeHolder: 'Address 2',
    },
    {
      id: 'city',
      label: 'Billing City',
      placeHolder: 'City',
    },
    {
      id: 'state',
      label: 'Billing State',
      placeHolder: 'State',
    },
    {
      id: 'zip',
      label: 'Billing Zip Code',
      placeHolder: 'XXXXX-XXXX',
    },
    {
      id: 'cardNumber',
      label: 'Credit Card Number',
      placeHolder: 'XXXXXXXXXXXXXXXX',
    },
    {
      id: 'cardCode',
      label: 'Security Code',
      placeHolder: 'XXX',
    },
    {
      id: 'promoCode',
      label: 'Promotion Code',
      placeHolder: 'XXXXXXXXXX',
    },
  ];

  const monthOptions = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const stateOptions = [
    'AK',
    'AL',
    'AR',
    'AS',
    'AZ',
    'CA',
    'CM',
    'CO',
    'CT',
    'DC',
    'DE',
    'FL',
    'GA',
    'GU',
    'HI',
    'IA',
    'ID',
    'IL',
    'IN',
    'KS',
    'KY',
    'LA',
    'MA',
    'MD',
    'ME',
    'MI',
    'MN',
    'MO',
    'MS',
    'MT',
    'NC',
    'ND',
    'NE',
    'NH',
    'NJ',
    'NM',
    'NV',
    'NY',
    'OH',
    'OK',
    'OR',
    'PA',
    'PR',
    'RI',
    'SC',
    'SD',
    'TN',
    'TT',
    'TX',
    'UT',
    'VA',
    'VI',
    'VT',
    'WA',
    'WI',
    'WV',
    'WY',
  ];

  const yearOptions = [2028, 2027, 2026, 2025, 2024, 2023, 2022];

  //
  // function: handleFormSubmit
  // desc: react to user submitting the form. validate input and then make API call
  //       to store it
  //
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    // BEGIN form data validation
    let errorsFound = false;
    const localErrorArr: string[] = [];

    const localErrorClasses: BillingFormErrorClasses = {
      firstName: '',
      lastName: '',
      address1: '',
      address2: '',
      city: '',
      zip: '',
      cardNumber: '',
      cardCode: '',
    };

    const errorMessages = {
      firstName: 'First name must be a string less than 64 characters long',
      lastName: 'Last name must be a string less than 64 characters long',
      address1: 'Please enter a valid billing address.',
      address2: 'Suite/Unit must be a string less than 64 characters long',
      city: 'Please enter a city.',
      state: 'Please select a state.',
      zip: 'Please enter a valid zip code.',
      cardNumber: 'Card number must be 15-16 digits long.',
      cardCode: 'Security Code must be 3 digits long.',
    };

    const formFieldRegexes = {
      firstName: /^.{1,64}$/,
      lastName: /^.{1,64}$/,
      cardNumber: /^\d{15,16}$/,
      cardCode: /^\d{3}/,
      address1: /^.{1,64}$/,
      address2: /^.{0,64}$/,
      city: /^.{1,64}$/,
      zip: /^\d{5}(?:[-\s]\d{4})?$/,
      promoCode: /^.{0,64}$/,
    };

    Object.keys(formFieldRegexes).forEach(formItem => {
      const formValue = (formData[formItem as keyof unknown] as string) || '';
      if (
        !formValue.toString().match(formFieldRegexes[formItem as keyof unknown])
      ) {
        errorsFound = true;
        localErrorArr.push(errorMessages[formItem as keyof unknown]);
        localErrorClasses[formItem as keyof BillingFormErrorClasses] =
          'has-error';
      }
    });

    if (errorsFound) {
      setHideErrorBox('');
    } else {
      setHideErrorBox('hide');
    }
    setErrorClass({ ...errorClass, ...localErrorClasses });
    setFormErrors(localErrorArr);
    // END form data validation

    if (hasToken && !errorsFound) {
      setIsSaving(true);
      const estId = searchParams.get('establishment_id');
      setSavedEstId(parseInt(estId || '0', 10));
      execute({
        method: 'POST',
        endpoint: `/api/subscriptions/${globalProfile.email}/establishments/${estId}`,
        requestBody: {
          billingProfile: {
            address: {
              firstName: formData.firstName,
              lastName: formData.lastName,
              address: formData.address1,
              address2: formData.address2,
              city: formData.city,
              state: formData.state,
              zip: formData.zip,
            },
            cardNumber: formData.cardNumber,
            cardCode: formData.cardCode,
            expirationYear: formData.expirationYear,
            expirationMonth: formData.expirationMonth,
          },
          amount: '99',
          promoCode: formData.promoCode,
        },
      });
    }
  };

  //
  // function: handleFormChange
  // desc: react to user changing values in the input fields of form.
  //        save values to state object for use when form is submitted
  //
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    if (isSaving) {
      if (!isLoading) {
        if (
          data.status === 'NOT_ACCEPTABLE' ||
          data.status === 'NOT_FOUND' ||
          data.status === 'CONFLICT'
        ) {
          // We got an error in the API call
          setHideErrorBox('');
          setFormErrors([data.message || '']);
          setIsSaving(false);
        } else {
          globalProfile.setEstablishmentSubscription(savedEstId, data);
          if (isOnboarding) {
            nav('/welcome');
          } else {
            nav('/profile');
          }
        }
      }
    }
  }, [isLoading]);

  return (
    <div className="section-container-lesspad">
      <div className="container">
        <FormErrorsBox
          formErrors={formErrors}
          hideErrorBoxClass={hideErrorBox}
        />
        <div className="row">
          <div className="col-md-6">
            <h3 className="page-title">Subscription Billing Information</h3>
          </div>
        </div>
        <form onSubmit={handleFormSubmit}>
          {formFields.map(fieldDefinition => (
            <div
              className={`form-group row ${
                errorClass[fieldDefinition.id as keyof BillingFormErrorClasses]
              }`}
              key={fieldDefinition.id}
            >
              <label
                htmlFor={fieldDefinition.id}
                className="col-sm-3 col-form-label"
              >
                {fieldDefinition.label}
              </label>
              <div className="col-sm-9">
                {(() => {
                  if (fieldDefinition.id === 'state') {
                    return (
                      <select
                        value={formData.state}
                        className="form-control"
                        style={{
                          width: '100px',
                        }}
                        name={fieldDefinition.id}
                        onChange={handleSelectChange}
                      >
                        {stateOptions.map(state => (
                          <option key={`k_${state}`} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    );
                  }
                  return (
                    <input
                      type="text"
                      className="form-control"
                      id={fieldDefinition.id}
                      name={fieldDefinition.id}
                      placeholder={fieldDefinition.placeHolder}
                      onChange={handleFormChange}
                      value={formData[fieldDefinition.id as keyof formDataObj]}
                    />
                  );
                })()}
              </div>
            </div>
          ))}
          <div className="form-group row">
            <label htmlFor="lastName" className="col-sm-3 col-form-label">
              Expiration Date
            </label>
            <div
              className="col-sm-9"
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <select
                value={formData.expirationMonth}
                className="form-control"
                style={{
                  width: '100px',
                }}
                name="expirationMonth"
                onChange={handleSelectChange}
              >
                {monthOptions.map((month, index) => (
                  <option key={`k_${month}`} value={index + 1}>
                    {month}
                  </option>
                ))}
              </select>
              <div>&nbsp;/&nbsp;</div>
              <select
                value={formData.expirationYear}
                className="form-control"
                style={{
                  width: '100px',
                }}
                name="expirationYear"
                onChange={handleSelectChange}
              >
                {yearOptions.map(year => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-12 text-right">
              <button
                type="submit"
                disabled={isSaving}
                className="btn btn-primary"
              >
                {(() => {
                  if (isSaving) {
                    return (
                      <span>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <div
                          style={{
                            float: 'right',
                            paddingTop: '2px',
                            paddingLeft: '4px',
                          }}
                        >
                          &nbsp;Saving...
                        </div>
                      </span>
                    );
                  }
                  return (
                    <div
                      style={{
                        float: 'right',
                        paddingTop: '2px',
                        paddingLeft: '4px',
                        paddingRight: '4px',
                      }}
                    >
                      &nbsp;Save
                    </div>
                  );
                })()}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BillingSubscription;
