import React from 'react';
import { Tooltip } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { ToolTipTheme } from '../../themes/MUIThemes';

function EditPeersButton(): React.ReactElement | null {
  return (
    <ThemeProvider theme={ToolTipTheme}>
      <Tooltip
        title="Contact help@radarreport.com to edit your group."
        placement="top"
      >
        <span className="peer-remove-button">
          <button type="button" className="btn btn-disabled">
            Edit
          </button>
        </span>
      </Tooltip>
    </ThemeProvider>
  );
}

export default EditPeersButton;
