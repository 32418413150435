import React, { useState, useEffect, useRef } from 'react';
import FormErrorsBox from './FormErrorsBox';
import useApiCall from '../hooks/useApiCall';
import { useGlobalProfile } from '../hooks/useGlobalProfile';
import { useGetEstTypeOptions } from '../hooks/useGetEstTypeOptions';
import { Establishment, EstablishmentFormErrorClasses } from '../Interfaces';

type EstablishmentFormProps = {
  advanceStep: () => void;
  formMode: string;
  editEstablishmentId: number;
};

function EstablishmentForm(props: EstablishmentFormProps): React.ReactElement {
  const { profile: globalProfile } = useGlobalProfile();
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [editTarget, setEditTarget] = useState<Establishment>();
  const { advanceStep, formMode, editEstablishmentId } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [hideErrorBox, setHideErrorBox] = useState('hide');
  const { hasToken, isLoading, data, error, execute } = useApiCall();
  const { optionsFetched, optionsData } = useGetEstTypeOptions();
  const inputBoxRef = useRef<HTMLInputElement>(null);
  const [formData, setFormData] = useState({
    establishmentName: '',
    establishmentType: 'FAST_CASUAL',
    address2: '',
    squareFootage: '',
    leaseAmount: '',
  });
  const [errorClass, setErrorClass] = useState<EstablishmentFormErrorClasses>({
    establishmentName: '',
    establishmentType: '',
    address1: '',
    address2: '',
    squareFootage: '',
    leaseAmount: '',
  });

  const [autoCompleter, setAutoCompleter] =
    React.useState<google.maps.places.Autocomplete>();

  // const handleCancel = (event: React.MouseEvent<HTMLElement>): void => {
  const handleCancel = (): void => {
    advanceStep();
  };

  //
  // function: handleFormSubmit
  // desc: react to user submitting the form. validate input and then make API call
  //       to store it
  //
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const place = autoCompleter?.getPlace();

    // BEGIN form data validation
    let errorsFound = false;
    const localErrorArr: string[] = [];

    const localErrorClasses: EstablishmentFormErrorClasses = {
      establishmentName: '',
      establishmentType: '',
      address1: '',
      address2: '',
      squareFootage: '',
      leaseAmount: '',
    };

    const errorMessages = {
      establishmentName: 'Name must be a string less than 64 characters long',
      establishmentType: '',
      address1: 'Please enter and select a valid address from picker.',
      address2: 'Suite/Unit must be a string less than 64 characters long',
      squareFootage: 'Square Footage must be a number.',
      leaseAmount: 'Lease Amount must be a number.',
    };

    const formFieldRegexes = {
      establishmentName: /^.{1,64}$/,
      leaseAmount: /^\d+$/,
      squareFootage: /^\d+$/,
      address2: /^.{0,64}$/,
    };

    if (!editEstablishmentId && (!place || !place.geometry)) {
      localErrorClasses.address1 = 'has-error';
      errorsFound = true;
      localErrorArr.push(errorMessages.address1);
    }

    Object.keys(formFieldRegexes).forEach(formItem => {
      const formValue = (formData[formItem as keyof unknown] as string) || '';
      if (
        !formValue.toString().match(formFieldRegexes[formItem as keyof unknown])
      ) {
        errorsFound = true;
        localErrorArr.push(errorMessages[formItem as keyof unknown]);
        localErrorClasses[formItem as keyof EstablishmentFormErrorClasses] =
          'has-error';
      }
    });

    if (errorsFound) {
      setHideErrorBox('');
    } else {
      setHideErrorBox('hide');
    }
    setErrorClass({ ...errorClass, ...localErrorClasses });
    setFormErrors(localErrorArr);
    // END form data validation

    if (
      !hasToken ||
      errorsFound ||
      ((formMode === 'onboarding' ||
        (formMode === 'profilePage' && !editEstablishmentId)) &&
        !place?.address_components)
    ) {
      // we don't meet the conditions to send API request
      return;
    }

    // if we got here we are going to send API request...let's get it ready
    setIsSaving(true);
    let address1 = '';
    let city = '';
    let state = '';
    let zipCode = '';
    let lat;
    let long;

    if (
      formMode === 'profilePage' &&
      editEstablishmentId > 0 &&
      (!place || !place.geometry)
    ) {
      // get address info from existing establishment in global profile
      address1 = editTarget?.address1 as string;
      city = editTarget?.city as string;
      state = editTarget?.state as string;
      zipCode = editTarget?.zip as string;
      lat = editTarget?.latitude;
      long = editTarget?.longitude;
    } else if (place?.address_components) {
      // get address info from Autocompleter
      address1 = `${place?.address_components[0].short_name} ${place?.address_components[1].short_name}`;
      city = place?.address_components[3].long_name;
      state = place?.address_components[5].short_name;
      zipCode =
        place?.address_components[7]?.short_name ||
        place?.address_components[6]?.short_name;
      lat = place.geometry?.location?.lat();
      long = place.geometry?.location?.lng();
    }

    const requestBody = {
      name: formData.establishmentName,
      establishmentType: {
        code: formData.establishmentType,
      },
      address1,
      address2: formData.address2,
      city,
      state,
      zipCode,
      latitude: lat,
      longitude: long,
      squareFootage: formData.squareFootage,
      leaseAmount: formData.leaseAmount,
    };

    if (!editEstablishmentId) {
      execute({
        method: 'PUT',
        endpoint: `/api/profiles/${globalProfile?.email}/establishments`,
        requestBody,
      });
    } else {
      execute({
        method: 'PUT',
        endpoint: `/api/establishments/${editEstablishmentId}`,
        requestBody,
      });
    }
  };

  const createGMapsAutoCompleter = (): void => {
    // center is San Diego
    const center = { lat: 32.7515739, lng: -117.08 };
    // Create a bounding box with sides ~30km away from the center point
    const defaultBounds = {
      north: center.lat + 0.3,
      south: center.lat - 0.3,
      east: center.lng + 0.3,
      west: center.lng - 0.3,
    };

    const options = {
      bounds: defaultBounds,
      componentRestrictions: { country: 'us' },
      fields: ['address_components', 'geometry'],
      strictBounds: false,
      types: ['address'],
    };

    const autoComplete = new google.maps.places.Autocomplete(
      inputBoxRef.current as HTMLInputElement,
      options,
    );
    setAutoCompleter(autoComplete);
  };

  useEffect(() => {
    if (!autoCompleter) {
      createGMapsAutoCompleter();
    }

    if (isSaving) {
      if (!isLoading) {
        if (
          data.status === 'NOT_ACCEPTABLE' ||
          data.status === 'NOT_FOUND' ||
          data.status === 'CONFLICT'
        ) {
          // We got an error in the API call
          setHideErrorBox('');
          setFormErrors([data.message || '']);
          setIsSaving(false);
        } else if (!error && data.id) {
          if (editEstablishmentId > 0) {
            // we need to update establishment data in globalProfile
            for (let i = 0; i < globalProfile.establishments.length; i += 1) {
              const est = globalProfile.establishments[i];
              if (est.id === data.id) {
                est.name = data.name;
                est.address1 = data.address1;
                est.city = data.city;
                est.state = data.state;
                est.zip = data.zipCode;
                est.latitude = data.latitude;
                est.longitude = data.longitude;
                est.establishmentType = data.establishmentType;
                est.squareFootage = data.squareFootage;
                est.leaseAmount = data.leaseAmount;
              }
            }
          } else {
            // we were adding a new establishment so let's make sure to set
            // activeEstablishmentId in case this was the first one
            globalProfile.activeEstablishmentId = data.establishments[0].id;
            globalProfile.establishments = data.establishments;
          }
          advanceStep();
        }
      }
    }

    // an establishment id was passed in as a prop so
    // we need to find the establishment we're going to be editing
    if (editEstablishmentId > 0) {
      globalProfile.establishments.forEach(est => {
        if (est.id === editEstablishmentId) {
          // set the establishment we are going to be editing
          setEditTarget(est);

          // populate form data object
          setFormData({
            ...formData,
            establishmentName: est.name,
            establishmentType: est.establishmentType.code,
            address2: est.address2,
            squareFootage: est.squareFootage || '',
            leaseAmount: est.leaseAmount || '',
          });
        }
      });
    }
  }, [isLoading]);

  //
  // function: handleFormChange
  // desc: react to user changing values in the input fields of form.
  //        save values to state object for use when form is submitted
  //
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  return (
    <div>
      <FormErrorsBox formErrors={formErrors} hideErrorBoxClass={hideErrorBox} />
      <form onSubmit={handleFormSubmit}>
        <div className={`form-group row ${errorClass.establishmentName}`}>
          <label
            htmlFor="establishmentName"
            className="col-sm-2 col-form-label"
          >
            Name
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="establishmentName"
              name="establishmentName"
              placeholder="Establishment Name"
              defaultValue={editEstablishmentId > 0 ? editTarget?.name : ''}
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className={`form-group row ${errorClass.establishmentType}`}>
          <label
            htmlFor="establishmentType"
            className="col-sm-2 col-form-label"
          >
            Type
          </label>
          <div className="col-sm-10">
            <select
              className="form-control"
              name="establishmentType"
              value={formData.establishmentType}
              onChange={handleSelectChange}
            >
              {optionsData.map(option => (
                <option key={`k_${option.code}`} value={option.code}>
                  {option.description}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className={`form-group row ${errorClass.address1}`}>
          <label htmlFor="address1" className="col-sm-2 col-form-label">
            Address
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="address1"
              name="address1"
              placeholder="Address"
              onChange={handleFormChange}
              defaultValue={
                editEstablishmentId > 0
                  ? editTarget?.address1.concat(
                      ', ',
                      editTarget?.city,
                      ', ',
                      editTarget.state,
                      ', USA',
                    )
                  : ''
              }
              ref={inputBoxRef}
            />
          </div>
        </div>
        <div className={`form-group row ${errorClass.address2}`}>
          <label htmlFor="address2" className="col-sm-2 col-form-label" />
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="address2"
              name="address2"
              placeholder="Suite / Unit / Etc"
              defaultValue={editEstablishmentId > 0 ? editTarget?.address2 : ''}
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className={`form-group row ${errorClass.squareFootage}`}>
          <label htmlFor="squareFootage" className="col-sm-2 col-form-label">
            Square Footage<sup>*</sup>
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="squareFootage"
              name="squareFootage"
              placeholder="Square Footage"
              defaultValue={
                editEstablishmentId > 0 ? editTarget?.squareFootage : ''
              }
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className={`form-group row ${errorClass.leaseAmount}`}>
          <label htmlFor="leaseAmount" className="col-sm-2 col-form-label">
            Lease Rate<sup>**</sup>
          </label>
          <div className="col-sm-10">
            <input
              type="text"
              className="form-control"
              id="leaseAmount"
              name="leaseAmount"
              placeholder="Lease Amount"
              defaultValue={
                editEstablishmentId > 0 ? editTarget?.leaseAmount : ''
              }
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12 text-right">
            {(() => {
              if (formMode === 'profilePage') {
                return (
                  <span>
                    <button
                      onClick={handleCancel}
                      type="button"
                      disabled={isSaving}
                      className="btn"
                    >
                      <div
                        style={{
                          float: 'right',
                          paddingTop: '2px',
                          paddingLeft: '4px',
                          paddingRight: '4px',
                        }}
                      >
                        Cancel
                      </div>
                    </button>
                    &nbsp;
                  </span>
                );
              }

              return <span />;
            })()}
            <button
              type="submit"
              disabled={isSaving || !optionsFetched}
              className="btn btn-primary"
            >
              {(() => {
                if (isSaving) {
                  return (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <div
                        style={{
                          float: 'right',
                          paddingTop: '2px',
                          paddingLeft: '4px',
                        }}
                      >
                        &nbsp;Saving...
                      </div>
                    </span>
                  );
                }

                return (
                  <div
                    style={{
                      float: 'right',
                      paddingTop: '2px',
                      paddingLeft: '4px',
                      paddingRight: '4px',
                    }}
                  >
                    &nbsp;Save
                  </div>
                );
              })()}
            </button>
          </div>
          <div
            className="form-group_row"
            style={{
              paddingTop: '96px',
              fontSize: 'small',
            }}
          >
            <div className="col-sm-2" />
            <div className="col-sm-10">
              <div>
                * Square footage is the amount of space in your lease, patios
                and attic space are included only if you pay rent on that square
                footage.
              </div>
              <div>
                ** Lease Rate is the amount you pay every month to occupy your
                space: Lease amount plus Common Area Maintenance or Monthly
                Mortgage plus Common Area Maintenance if you own the building.
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

// issues
// 500 error when tryig to update existing establishment
// need to check return value and update establishment info in global profile

export default EstablishmentForm;
