import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import BillingSubscription from './BillingSubscription';
import Dashboard from './Dashboard';
import DataCenter from './DataCenter';
import Establishments from './Establishments';
import Header from './Header';
import Profile from './Profile';
import SuggestEstablishment from './SuggestEstablishment';
import Welcome from './Welcome';

function FullUserApp(): React.ReactElement {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <div>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/billing_subscription"
              element={<BillingSubscription />}
            />
            <Route path="/datacenter" element={<DataCenter />} />
            <Route path="/establishments" element={<Establishments />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/suggest" element={<SuggestEstablishment />} />
            <Route path="/welcome" element={<Welcome />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default FullUserApp;
