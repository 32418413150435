import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import logo from '../assets/logo-new-mdfull.png';
import '../css/Header.css';

const { REACT_APP_LOGOUT_REDIRECT } = process.env;
function Header(): React.ReactElement {
  const [showMenu, setShowMenu] = useState(false);
  const { logout } = useAuth0();

  const toggleMenu = (): void => {
    setShowMenu(!showMenu);
  };

  const hideMenu = (): void => {
    setShowMenu(false);
  };

  const show = showMenu ? 'show' : '';

  return (
    <header>
      <nav className="navbar navbar-fixed-top navbar-default">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle uarr collapsed"
              onClick={toggleMenu}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <Link className="navbar-brand" to="/">
              <img
                src={logo}
                className="navbar-logo-img"
                style={{
                  height: '60px',
                  marginTop: '-18px',
                  marginLeft: '-15px',
                }}
                alt=""
              />
            </Link>
          </div>
          <div
            className={`collapse navbar-collapse ${show}`}
            id="navbar-collapse-uarr"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link to="/">
                  <span role="presentation" onClick={hideMenu}>
                    Dashboard
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/datacenter">
                  <span role="presentation" onClick={hideMenu}>
                    Data Center
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/establishments">
                  <span role="presentation" onClick={hideMenu}>
                    Establishments
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/profile">
                  <span role="presentation" onClick={hideMenu}>
                    Profile
                  </span>
                </Link>
              </li>
              <li>
                <Link
                  to="/"
                  onClick={() => logout({ returnTo: REACT_APP_LOGOUT_REDIRECT })}
                >
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
