import React from 'react';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { Establishment } from '../../Interfaces';

type PeerEstablishmentButtonProps = {
  establishment: Establishment;
  hasPeerSetSaved: boolean;
  focusPeerFunction: (establishmentId: number) => void;
  removePeerFunction: (establishmentId: number) => void;
};

function PeerEstablishmentButton(
  props: PeerEstablishmentButtonProps,
): React.ReactElement | null {
  const {
    establishment,
    hasPeerSetSaved,
    focusPeerFunction,
    removePeerFunction,
  } = props;

  return (
    <div className="peer-button-group">
      <div className="peer-button">
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => focusPeerFunction(establishment.id)}
        >
          {establishment.name}
        </button>
      </div>
      {(() => {
        if (!hasPeerSetSaved) {
          return (
            <div className="peer-remove-button">
              <button
                type="button"
                className="btn"
                onClick={() => removePeerFunction(establishment.id)}
              >
                <RemoveCircleOutlineIcon fontSize="medium" />
              </button>
            </div>
          );
        }
        return <span />;
      })()}
    </div>
  );
}

export default PeerEstablishmentButton;
