import React from 'react';
import '../css/Loading.css';
import logo from '../assets/logo-new-mdfull.png';
import loader from '../assets/loader.gif';

type LoadingProps = {
  message: string;
};

function Loading(props: LoadingProps): React.ReactElement {
  const { message } = props;

  return (
    <div className="center-container">
      <div>
        <img src={logo} alt="Radar Report" className="loading-logo" />
      </div>
      <div>
        <img src={loader} alt="Loading" className="loading-loop" />
      </div>
      <div>{message}</div>
    </div>
  );
}

export default Loading;
