import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  createLineChartEntries,
  formatLineChartTick,
  formatToolTipDate,
} from '../util/ChartDataUtils';
import { EstablishmentData, ReportData } from '../Interfaces';
import { ChartCategoryEnum } from '../models/ChartCategoryEnum';

type LineChartProps = {
  establishmentData: EstablishmentData[];
  yLabel: string;
  yLabelSymbol: string;
  chartCategory: ChartCategoryEnum;
  reportDataMap: { [subject: string]: ReportData[] };
  periodDuration: string;
  establishmentCategory: string;
};

/**
 * Create a Generic Radar Report Bar Chart with Xaxis for each subject
 * type: ALL, CATEGORY, PEER, YOU
 *
 * @param props
 * @constructor
 */
function RadarLineChart(props: LineChartProps): React.ReactElement {
  const {
    establishmentData,
    yLabel,
    yLabelSymbol,
    chartCategory,
    reportDataMap,
    periodDuration,
    establishmentCategory,
  } = props;

  // generate the line chart list data for the specific chart category
  const chartDataList = createLineChartEntries(
    establishmentData,
    chartCategory,
    reportDataMap,
  );

  return chartDataList.length > 0 ? (
    <ResponsiveContainer height={400}>
      <LineChart
        data={chartDataList}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          interval="preserveStartEnd"
          tickFormatter={date =>
            formatLineChartTick(new Date(date), periodDuration)
          }
        />
        <YAxis
          tickFormatter={tick => {
            return yLabelSymbol === 'PERCENT' ? `${tick}%` : `$${tick}`;
          }}
        />
        <Tooltip
          labelFormatter={date => formatToolTipDate(date, periodDuration)}
          formatter={(value: number) =>
            yLabelSymbol === 'PERCENT'
              ? `${value}%`
              : `$${new Intl.NumberFormat('en').format(Math.round(value))}`
          }
        />
        <Legend />
        <Line
          type="monotone"
          dataKey="ALL"
          stroke="#454449FF"
          strokeWidth={3}
        />
        <Line
          type="monotone"
          dataKey="CATEGORY"
          name={establishmentCategory}
          stroke="#379DF7FF"
          strokeWidth={3}
        />
        <Line
          type="monotone"
          dataKey="PEER"
          stroke="#CE2E54FF"
          strokeWidth={3}
        />
        <Line
          type="monotone"
          dataKey="YOU"
          stroke="#17BE6AFF"
          strokeWidth={3}
        />
      </LineChart>
    </ResponsiveContainer>
  ) : (
    <ResponsiveContainer>
      <LineChart />
    </ResponsiveContainer>
  );
}

export default RadarLineChart;
