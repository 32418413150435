import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { RequestInfo, UseAPIReturn } from '../Interfaces';

const useApiCall = (): UseAPIReturn => {
  const [hasToken, setHasToken] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<Error>();
  const [token, setToken] = useState('');
  const { getAccessTokenSilently } = useAuth0();

  // Make API request
  const execute = async (requestParams: RequestInfo): Promise<boolean> => {
    setIsLoading(true);

    const reqHeaders = new Headers({
      'Content-Type': 'application/json',
    });

    if (!requestParams.noAuth) {
      reqHeaders.append('Authorization', `Bearer ${token}`);
    }

    const requestOptions: RequestInit = {
      method: requestParams.method,
      headers: reqHeaders,
    };

    if (requestParams.requestBody) {
      requestOptions.body = JSON.stringify(requestParams.requestBody);
    }

    try {
      const response = await fetch(
        process.env.REACT_APP_API_SERVER_PREFIX + requestParams.endpoint,
        requestOptions,
      );

      if (requestParams.textResponse) {
        setData({ text: await response.text() });
      } else {
        setData(await response.json());
      }
      setIsLoading(false);
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
        setIsLoading(false);
      }
    }
    return true;
  };

  // Fetch token for API calls
  const fetchToken = async (): Promise<boolean> => {
    try {
      const accessToken = await getAccessTokenSilently({
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      });
      setToken(accessToken);
      setHasToken(true);
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
    }
    return true;
  };

  useEffect(() => {
    if (token === '') {
      fetchToken();
    }
  }, []);

  return { hasToken, isLoading, data, error, execute };
};

export default useApiCall;
