import React from 'react';
import { Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { useGlobalProfile } from '../../hooks/useGlobalProfile';

type EstablishmentTableProps = {
  handleShowSubscriptionPopup: (establishmentId: number) => void;
  handleShowEditDataFeedPopup: (establishmentId: number) => void;
  handleEditEstablishment: (id: number) => void;
  handleAddEstablishment: (event: React.FormEvent<HTMLFormElement>) => void;
};

function EstablishmentTable(
  props: EstablishmentTableProps,
): React.ReactElement {
  const { profile: globalProfile } = useGlobalProfile();
  const {
    handleShowSubscriptionPopup,
    handleShowEditDataFeedPopup,
    handleEditEstablishment,
    handleAddEstablishment,
  } = props;

  return (
    <div className="container">
      <h3 className="page-title">Your Establishments</h3>
      <Divider />
      <br />
      <table className="table table-hover table-bordered">
        <thead className="thead-light">
          <tr>
            <th className="active" scope="col">
              Name
            </th>
            <th className="active" scope="col">
              Subscription Type
            </th>
            <th className="active" scope="col">
              Data Source
            </th>
            <th className="active" scope="col">
              Sq. Footage
            </th>
            <th className="active" scope="col">
              Lease Rate
            </th>
            <th className="active" scope="col">
              {}
            </th>
          </tr>
        </thead>
        <tbody>
          {globalProfile.establishments.map(establishment => (
            <tr key={establishment.id}>
              <td className="text-left">{establishment.name}</td>
              <td className="text-left">
                {(() => {
                  if (establishment.subscriptionType !== 'PREMIUM') {
                    return (
                      <div className="subscription-cell">
                        FREE
                        <Link
                          to={`/billing_subscription?establishment_id=${establishment.id}`}
                        >
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                          >
                            Upgrade
                          </button>
                        </Link>
                      </div>
                    );
                  }
                  return (
                    <div className="subscription-cell">
                      <span>{establishment.subscriptionType}</span>
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        title="Subscription Info"
                        onClick={() =>
                          handleShowSubscriptionPopup(establishment.id)
                        }
                      >
                        <div className="icon-button-div">
                          <InfoIcon />
                        </div>
                      </button>
                    </div>
                  );
                })()}
              </td>
              <td className="text-left">
                <div className="subscription-cell">
                  <span>
                    {establishment.pointOfSaleIntegration
                      ?.slice(0, 1)
                      .concat(
                        establishment.pointOfSaleIntegration
                          ?.slice(1)
                          .toLowerCase(),
                      )}
                  </span>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    title="Edit Data Source"
                    onClick={() =>
                      handleShowEditDataFeedPopup(establishment.id)
                    }
                  >
                    <div className="icon-button-div">
                      <EditIcon />
                    </div>
                  </button>
                </div>
              </td>
              <td>{establishment.squareFootage}</td>
              <td>{establishment.leaseAmount}</td>
              <td>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  title="Edit"
                  onClick={() => handleEditEstablishment(establishment.id)}
                >
                  <div className="icon-button-div">
                    <EditIcon />
                  </div>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <form onSubmit={handleAddEstablishment}>
        <div className="form-group row">
          <div className="col-sm-12 text-right">
            <button type="submit" className="btn btn-primary">
              <div
                style={{
                  float: 'right',
                  paddingTop: '2px',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                }}
              >
                &nbsp;Add Establishment
              </div>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default EstablishmentTable;
