import React, { useState, useEffect } from 'react';
import FormErrorsBox from './FormErrorsBox';
import useApiCall from '../hooks/useApiCall';
import { useGlobalProfile } from '../hooks/useGlobalProfile';
import { UserFormErrorClasses } from '../Interfaces';

type UserProfileFormProps = {
  advanceStep: () => void;
  formMode: string;
};

function UserProfileForm(props: UserProfileFormProps): React.ReactElement {
  const { profile: globalProfile } = useGlobalProfile();
  const { advanceStep, formMode } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [hideErrorBox, setHideErrorBox] = useState('hide');
  const { hasToken, isLoading, data, error, execute } = useApiCall();
  const [formData, setFormData] = useState({
    firstName: formMode === 'profilePage' ? globalProfile.firstName : '',
    lastName: formMode === 'profilePage' ? globalProfile.lastName : '',
    phoneNumber:
      formMode === 'profilePage' ? globalProfile.phoneNumber.toString() : '',
  });
  const [errorClass, setErrorClass] = useState<UserFormErrorClasses>({
    firstName: '',
    lastName: '',
    phoneNumber: '',
  });

  //
  // function: handleFormSubmit
  // desc: react to user submitting the form. validate input and then make API call
  //       to store it
  //
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    // BEGIN form data validation
    let errorsFound = false;
    const localErrorArr: string[] = [];

    const localErrorClasses: UserFormErrorClasses = {
      firstName: '',
      lastName: '',
      phoneNumber: '',
    };

    const errorMessages = {
      firstName: 'First name must be a string less than 64 characters long',
      lastName: 'Last name must be a string less than 64 characters long',
      phoneNumber: 'Phone number must be 10 digits',
    };

    const formFieldRegexes = {
      firstName: /^.{1,64}$/,
      lastName: /^.{1,64}$/,
      phoneNumber: /^\d{10}$/,
    };

    Object.keys(formFieldRegexes).forEach(formItem => {
      const formValue = formData[formItem as keyof unknown] as string;
      if (!formValue.match(formFieldRegexes[formItem as keyof unknown])) {
        errorsFound = true;
        localErrorArr.push(errorMessages[formItem as keyof unknown]);
        localErrorClasses[formItem as keyof UserFormErrorClasses] = 'has-error';
      }
    });

    if (errorsFound) {
      setHideErrorBox('');
    } else {
      setHideErrorBox('hide');
    }
    setErrorClass({ ...errorClass, ...localErrorClasses });
    setFormErrors(localErrorArr);
    // END form data validation

    if (hasToken && !errorsFound) {
      setIsSaving(true);

      if (formMode === 'onboarding') {
        execute({
          method: 'POST',
          endpoint: '/api/profiles',
          requestBody: {
            username: globalProfile.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phone: formData.phoneNumber,
            email: globalProfile.email,
            roleId: null,
            establishments: [],
          },
        });
      } else if (formMode === 'profilePage') {
        // do stuff here
        execute({
          method: 'PUT',
          endpoint: `/api/profiles/${globalProfile?.email}`,
          requestBody: {
            id: globalProfile.id,
            username: globalProfile.email,
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: globalProfile.email,
            phone: formData.phoneNumber,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (isSaving) {
      if (!isLoading) {
        if (
          data.status === 'NOT_ACCEPTABLE' ||
          data.status === 'NOT_FOUND' ||
          data.status === 'CONFLICT'
        ) {
          // We got an error in the API call
          setHideErrorBox('');
          setFormErrors([data.message || '']);
          setIsSaving(false);
        } else if (!error && data.id) {
          if (formMode === 'onboarding') {
            globalProfile.id = data.id;
          }
          globalProfile.firstName = data.firstName;
          globalProfile.lastName = data.lastName;
          globalProfile.phoneNumber = data.phone;
          setIsSaving(false);
          advanceStep();
        }
      }
    }
  }, [isLoading]);

  //
  // function: handleFormChange
  // desc: react to user changing values in the input fields of form.
  //        save values to state object for use when form is submitted
  //
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const saveValue =
      event.target.name === 'phoneNumber'
        ? event.target.value.replace(/\D/g, '')
        : event.target.value;

    setFormData({ ...formData, [event.target.name]: saveValue });
  };

  return (
    <div>
      <FormErrorsBox formErrors={formErrors} hideErrorBoxClass={hideErrorBox} />
      <form onSubmit={handleFormSubmit}>
        <div className={`form-group row ${errorClass.firstName}`}>
          <label htmlFor="firstName" className="col-sm-3 col-form-label">
            First Name
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              id="firstName"
              name="firstName"
              placeholder="First Name"
              defaultValue={
                formMode === 'profilePage' ? globalProfile.firstName : ''
              }
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className={`form-group row ${errorClass.lastName}`}>
          <label htmlFor="lastName" className="col-sm-3 col-form-label">
            Last Name
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              defaultValue={
                formMode === 'profilePage' ? globalProfile.lastName : ''
              }
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className={`form-group row ${errorClass.phoneNumber}`}>
          <label htmlFor="phoneNumber" className="col-sm-3 col-form-label">
            Phone Number
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="(XXX) XXX-XXXX"
              defaultValue={
                formMode === 'profilePage' ? globalProfile.phoneNumber : ''
              }
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12 text-right">
            <button
              type="submit"
              disabled={isSaving}
              className="btn btn-primary"
            >
              {(() => {
                if (isSaving) {
                  return (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <div
                        style={{
                          float: 'right',
                          paddingTop: '2px',
                          paddingLeft: '4px',
                        }}
                      >
                        &nbsp;Saving...
                      </div>
                    </span>
                  );
                }

                return (
                  <div
                    style={{
                      float: 'right',
                      paddingTop: '2px',
                      paddingLeft: '4px',
                      paddingRight: '4px',
                    }}
                  >
                    &nbsp;Save
                  </div>
                );
              })()}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default UserProfileForm;
