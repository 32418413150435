import React, { useEffect, useState } from 'react';
import useApiCall from './useApiCall';
import { UserProfile, UseGetProfileReturn } from '../Interfaces';

const useGetProfile = (email?: string): UseGetProfileReturn => {
  const [hasProfile, setHasProfile] = useState(false);
  const [profile, setProfile] = React.useState<UserProfile>();
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [fetched, setFetched] = useState(false);
  const { hasToken, isLoading, data, error, execute } = useApiCall();

  useEffect(() => {
    if (hasToken && !fetched) {
      setFetched(true);
      execute({
        method: 'GET',
        endpoint: `/api/profiles/${email}`,
      });
    }

    if (fetched && !isLoading) {
      setIsLoadingProfile(false);

      if (data) {
        if (data.status === 'NOT_FOUND') {
          setHasProfile(false);
        } else if (data.id) {
          setHasProfile(true);
          setProfile(data);
        }
      }

      if (error) {
        setHasProfile(false);
      }
      // console.log('Use Get Profile');
      // console.log("Data");
      // console.log(data);
      // console.log("Err");
      // console.log(error);
    }
  }, [hasToken, isLoading]);

  return { isLoadingProfile, hasProfile, profile };
};

export default useGetProfile;
