import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalProfile } from '../../hooks/useGlobalProfile';

type OnboardingSelectPlanProps = {
  advanceStep: () => void;
};

function OnboardingSelectPlan(
  props: OnboardingSelectPlanProps,
): React.ReactElement {
  const { advanceStep } = props;
  const nav = useNavigate();
  const { profile: globalProfile } = useGlobalProfile();

  // temporarily skip Choose Plan step
  useEffect(() => {
    nav('/welcome');
    advanceStep();
  }, []);

  const handleSelect = (planType: string): void => {
    if (planType === 'premium') {
      nav(
        `/billing_subscription?establishment_id=${globalProfile?.activeEstablishmentId}&onboarding=1`,
      );
    } else {
      nav('/welcome');
    }
    advanceStep();
  };

  return (
    <div>
      <div className="section-container" id="pricing">
        <div className="container" style={{ width: '100%' }}>
          <div className="row">
            <div className="col-md-6">
              <div className="pricing-card pricing-primary">
                <h2>Standard</h2>
                <h6 className="price">FREE</h6>
              </div>
              <div className="pricing-features">
                <ul className="features" style={{ minHeight: '350px' }}>
                  <li>Your sales against the local marketplace</li>
                  <li>Your lease rate against the local marketplace</li>
                  <li>
                    Your revenue per square foot against the local market place
                  </li>
                </ul>
                <div className="text-center">
                  <input
                    type="button"
                    className="btn btn-primary"
                    value="Select"
                    onClick={() => handleSelect('free')}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pricing-card pricing-info">
                <h2>Peer Insights</h2>
                <h6 className="price">
                  <span className="dollar">$</span>99<span>/MO</span>
                </h6>
              </div>
              <div className="pricing-features">
                <ul className="features" style={{ minHeight: '350px' }}>
                  <li>
                    Your sales against the local marketplace, your unique
                    establishment type and your specific comparable group.
                  </li>
                  <li>
                    Your lease rate against the local market place, your unique
                    establishment type and your specific comparable group.
                  </li>
                  <li>
                    Your revenue per square foot against the local market place,
                    your unique establishment type and your specific comparable
                    group.
                  </li>
                </ul>
                <div className="text-center">
                  <input
                    type="button"
                    className="btn btn-primary"
                    value="Select"
                    onClick={() => handleSelect('premium')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnboardingSelectPlan;
