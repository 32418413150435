import React, { useEffect, useState } from 'react';
import useApiCall from './useApiCall';
import { EstablishmentData, MonthlyDataResponse } from '../Interfaces';

export const useGetMonthlyData = (
  establishmentId?: number,
): MonthlyDataResponse => {
  const [isLoadingMonthlyData, setIsLoadingMonthlyData] = useState(true);
  const [reportDataStore, setReportDataStore] = React.useState<
    Map<number, EstablishmentData[]>
  >(new Map());
  const [fetched, setFetched] = useState(false);
  const [rawMonthlyData, setRawMonthlyData] = useState<EstablishmentData[]>([]);
  const [fetchToggle, setFetchToggle] = useState(0);
  const [fetchId, setFetchId] = useState(establishmentId);
  const { hasToken, isLoading, data, error, execute } = useApiCall();

  const forceFetch = (): void => {
    setFetchToggle(fetchToggle + 1);
  };

  const parseResponseData = (responseData: EstablishmentData[]): void => {
    const dataMap = new Map();
    setRawMonthlyData(responseData);
    responseData.forEach(dataPoint => {
      if (!dataMap.get(dataPoint.year)) {
        dataMap.set(dataPoint.year, []);
      }
      dataMap.get(dataPoint.year)[dataPoint.month - 1] = dataPoint;
    });

    // if the data set doesn't contain the current year, fill the map
    let checkMoreYears = true;
    let yearToCheck = new Date().getFullYear();
    while (checkMoreYears) {
      if (!dataMap.get(yearToCheck)) {
        dataMap.set(yearToCheck, []);
        yearToCheck -= 1;
      } else {
        checkMoreYears = false;
      }

      // safety check
      if (yearToCheck < 2000) {
        checkMoreYears = false;
      }
    }
    setReportDataStore(dataMap);
  };

  useEffect(() => {
    if (hasToken && !fetched) {
      setIsLoadingMonthlyData(true);
      setFetched(true);
      execute({
        method: 'GET',
        endpoint: `/api/establishments/${fetchId}/data?period=MONTHLY&periodCount=48`,
      });
    }

    if (fetched && !isLoading) {
      setIsLoadingMonthlyData(false);
      setFetched(false);
      if (data) {
        parseResponseData(data);
      }

      if (error) {
        // do something
      }
    }
  }, [hasToken, isLoading, fetchId, fetchToggle]);
  return {
    isLoadingMonthlyData,
    setFetchId,
    forceFetch,
    reportDataStore,
    rawMonthlyData,
  };
};

// export default useGetReportData;
