import React, { useEffect, useRef, useState } from 'react';
import FormErrorsBox from '../FormErrorsBox';
import useApiCall from '../../hooks/useApiCall';
import { useGlobalProfile } from '../../hooks/useGlobalProfile';
import { ToastConfigFormErrorClasses, ToastConfig } from '../../Interfaces';

type MonthlyDataFormProps = {
  formMode: string;
  formDone: () => void;
  formSaved: () => void;
  editData: ToastConfig;
};

function ToastConfigForm(props: MonthlyDataFormProps): React.ReactElement {
  const { hasToken, isLoading, data, execute } = useApiCall();
  const { profile: globalProfile } = useGlobalProfile();
  const [hideErrorBox, setHideErrorBox] = useState('hide');
  const formRef = useRef<HTMLFormElement>(null);
  const { formDone, formSaved, formMode, editData } = props;
  const [isSaving, setIsSaving] = useState(false);
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [formData, setFormData] = useState({
    sftpUrl: editData.sftpUrl,
    sftpUser: editData.sftpUser,
    configName: editData.configName,
  });
  const [errorClass, setErrorClass] = useState<ToastConfigFormErrorClasses>({
    sftpUrl: '',
    sftpUser: '',
    configName: '',
  });

  const resetAndCloseForm = (): void => {
    setHideErrorBox('hide');
    setErrorClass({
      sftpUrl: '',
      sftpUser: '',
      configName: '',
    });
    setFormErrors([]);
    formDone();
  };

  //
  // function: handleFormChange
  // desc: react to user changing values in the input fields of form.
  //        save values to state object for use when form is submitted
  //
  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.replaceAll(',', ''),
    });
  };

  //
  // function: handleFormSubmit
  // desc: react to user submitting the form. validate input and then make API call
  //       to store it
  //
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    // BEGIN form data validation
    let errorsFound = false;
    const localErrorArr: string[] = [];

    const localErrorClasses: ToastConfigFormErrorClasses = {
      sftpUrl: '',
      sftpUser: '',
      configName: '',
    };

    const formFieldRegexes = {
      sftpUrl: /^[-\w.]+\.\w+$/,
      sftpUser: /^\w+$/,
      configName: /^.{1,64}$/,
    };

    const errorMessages = {
      configName: 'Name is required and must be less than 64 characters.',
      sftpUser: 'Username is required and can only contain letter and numbers.',
      sftpUrl: 'SFTP Url is invalid.',
    };

    ['configName', 'sftpUser', 'sftpUrl'].forEach(formItem => {
      let itemError = false;
      const formValue = formData[formItem as keyof unknown] as string;

      if (
        !formValue.toString().match(formFieldRegexes[formItem as keyof unknown])
      ) {
        itemError = true;
      }

      if (parseFloat(formValue) <= 0) {
        itemError = true;
      }

      if (itemError) {
        errorsFound = true;
        localErrorArr.push(errorMessages[formItem as keyof unknown]);
        localErrorClasses[formItem as keyof ToastConfigFormErrorClasses] =
          'has-error';
      }
    });

    if (errorsFound) {
      setHideErrorBox('');
    } else {
      setHideErrorBox('hide');
    }
    setErrorClass({ ...errorClass, ...localErrorClasses });
    setFormErrors(localErrorArr);
    // END form data validation

    if (hasToken && !errorsFound) {
      setIsSaving(true);

      const requestBody = {
        username: globalProfile.email,
        sftpUrl: formData.sftpUrl,
        sftpUser: formData.sftpUser,
        configName: formData.configName,
      };

      if (formMode === 'ADD') {
        execute({
          method: 'POST',
          endpoint: '/api/pos/toast',
          requestBody,
        });
      } else {
        execute({
          method: 'PUT',
          endpoint: `/api/pos/toast/${editData.id}`,
          requestBody,
        });
      }
    }
  };

  useEffect(() => {
    if (!isSaving) {
      setFormData({
        sftpUrl: editData.sftpUrl,
        sftpUser: editData.sftpUser,
        configName: editData.configName,
      });
    }

    if (isSaving) {
      if (!isLoading) {
        if (
          data.status === 'NOT_ACCEPTABLE' ||
          data.status === 'NOT_FOUND' ||
          data.status === 'CONFLICT'
        ) {
          // We got an error in the API call
          setHideErrorBox('');
          setFormErrors([data.message || '']);
          setIsSaving(false);
        } else {
          setIsSaving(false);
          formSaved();
          if (formRef.current) {
            formRef.current.reset();
          }
        }
      }
    }
  }, [editData, isLoading]);

  return (
    <div>
      <FormErrorsBox formErrors={formErrors} hideErrorBoxClass={hideErrorBox} />
      <form onSubmit={handleFormSubmit} ref={formRef}>
        <div className={`form-group row ${errorClass.configName}`}>
          <label htmlFor="configName" className="col-sm-3 col-form-label">
            Name
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              id="configName"
              name="configName"
              placeholder="Configuration Name"
              value={formData.configName}
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className={`form-group row ${errorClass.sftpUser}`}>
          <label htmlFor="sftpUser" className="col-sm-3 col-form-label">
            SFTP Username
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              id="sftpUser"
              name="sftpUser"
              placeholder="SFTP Username"
              value={formData.sftpUser}
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className={`form-group row ${errorClass.sftpUrl}`}>
          <label htmlFor="sftpUrl" className="col-sm-3 col-form-label">
            SFTP URL
          </label>
          <div className="col-sm-9">
            <input
              type="text"
              className="form-control"
              id="sftpUrl"
              name="sftpUrl"
              placeholder="SFTP Url"
              value={formData.sftpUrl}
              onChange={handleFormChange}
            />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-12 text-right">
            <span>
              <button
                onClick={resetAndCloseForm}
                type="button"
                disabled={isSaving}
                className="btn"
              >
                <div
                  style={{
                    float: 'right',
                    paddingTop: '2px',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                  }}
                >
                  Cancel
                </div>
              </button>
              &nbsp;
            </span>
            <button
              type="submit"
              disabled={isSaving}
              className="btn btn-primary"
            >
              {(() => {
                if (isSaving) {
                  return (
                    <span>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      />
                      <div
                        style={{
                          float: 'right',
                          paddingTop: '2px',
                          paddingLeft: '4px',
                        }}
                      >
                        &nbsp;Saving...
                      </div>
                    </span>
                  );
                }

                return (
                  <div
                    style={{
                      float: 'right',
                      paddingTop: '2px',
                      paddingLeft: '4px',
                      paddingRight: '4px',
                    }}
                  >
                    &nbsp;Save
                  </div>
                );
              })()}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ToastConfigForm;
